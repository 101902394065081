import React, { useContext, useEffect, useState } from 'react';
import { ArticleContextProvider } from '../context/ArticleContext';

import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { NewsHRDivider, SingleNewsMainContent, SingleNewsContent } from '../styles/newsStyles';
import { NewsSubNavigation } from '../components/V2/News/NewsSubNavigation/NewsSubNavigation';
import { TagsWrap } from '../components/V2/News/TagsWrap/TagsWrap';

import { createMarkUp } from '../components/V2/helpers';

interface Props {
  Lang?: string;
  pageContext?: {
    article: any;
  };
}

const SingleArticleTemplate = ({ Lang = 'zh-cn', pageContext, ...props }: Props) => {
  const ArticleData = pageContext?.article.node;

  const globalCopy = require(`../../data/content/Global/${Lang}.json`);
  const vertical = '';

  console.log(ArticleData);

  if (ArticleData) {
    return (
      <ArticleContextProvider ArticleData={ArticleData}>
        <HelmetContainer lang={Lang} title={ArticleData.title} image={ArticleData.featuredImage} />

        <PageContainer lang={Lang}>
          <Header lang={Lang} />

          <NewsSubNavigation />

          <div className={'container'}>
            <SingleNewsMainContent className={'lang-zh-cn'}>
              <h1>{ArticleData.title}</h1>
              <hr />
            </SingleNewsMainContent>
            <SingleNewsContent className={'lang-zh-cn'}>
              <div className="copy" dangerouslySetInnerHTML={createMarkUp(ArticleData.content)}></div>
            </SingleNewsContent>

            <div></div>

            <NewsHRDivider />
            <TagsWrap Categories={ArticleData.tags} />
          </div>
        </PageContainer>
        <Footer lang={Lang} />
      </ArticleContextProvider>
    );
  } else {
    return null;
  }
};

export default SingleArticleTemplate;
