import React from 'react';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { LargeHero } from '../components/V2/LargeHero/LargeHero';
import { ResourceLinks } from '../components/V2/ResourceLinks/ResourceLinks';
import { SplitColumn } from '../components/V2/SplitColumn/SplitColumn';
import { TextColumns } from '../components/V2/TextColumns/TextColumns';
import { BackgroundWave } from '../GlobalStyles';
import { ArticleBlock } from '../components/V2/ArticleBlock/ArticleBlock';

const ThreeDInspectionSolution = () => {
  const Lang = 'zh-cn';
  const data = require(`../../data/content/Pages/threed-scanning-solutions/${Lang}.json`);
  const globalCopy = require(`../../data/content/Global/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer
        currentPage={'3d-scanning-solutions'}
        lang={Lang}
        title={data.helmet.title}
        description={data.helmet.description}
      />

      <BackgroundWave>
        <Header lang={Lang} />
        <LargeHero
          AddSalesTel={true}
          Country={Lang}
          BackgroundStyle={'image'}
          Copy={data.content.Hero.copy}
          Background={'/assets/images/solutions/3d-scanning-solutions-key-visual.webp'}
          Button={{
            copy: globalCopy.Navigation.Contact,
            OpenDemoModel: true,
            theme: 'accent-red'
          }}
        />
      </BackgroundWave>

      <TextColumns
        Lang={Lang}
        Columns={'1'}
        Copy={data.content.TextColumns.copy}
        Title={data.content.TextColumns.title}
        Logos={['geomagic-designx', 'geomagic-controlx', 'geomagic-wrap', 'geomagic-for-solid']}
      />

      <SplitColumn
        ImagePosition={'Left'}
        Copy={data.content.SplitCol1.copy}
        Image={{
          src: '/assets/images/solutions/3d-reverse-engineering-key-visual.webp',
          alt: ''
        }}
        Button={{
          copy: globalCopy.Buttons.FindOutMore,
          link: globalCopy.Navigation.FixedURLS.ReverseEngineering.fixedUrl,
          theme: 'white-outline'
        }}
      />
      <SplitColumn
        ImagePosition={'Right'}
        ImageOverlap={false}
        ImageBackground={false}
        Copy={data.content.SplitCol2.copy}
        Background={'White'}
        Image={{
          src: '/assets/images/solutions/inspection-key-visual-v2.webp',
          alt: ''
        }}
        Button={{
          copy: globalCopy.Buttons.FindOutMore,
          link: globalCopy.Navigation.FixedURLS.InspectionSofware.fixedUrl,
          theme: 'white-outline'
        }}
      />
      <SplitColumn
        ImagePosition={'Left'}
        ImageOverlap={false}
        ImageBackground={false}
        Copy={data.content.SplitCol3.copy}
        Background={'Primary'}
        Image={{
          src: '/assets/images/solutions/3d-scanning-solutions-hardware.webp',
          alt: ''
        }}
      />

      <ResourceLinks
        Title={data.content.ResourceLinks.title}
        Copy={data.content.ResourceLinks.copy}
        Button={{
          Label: data.content.ResourceLinks.button_label,
          Link: '/news'
        }}
        Items={[
          {
            category: globalCopy.Categories.Blog,
            title: data.content.ResourceLinks.item_title_1,
            link: {
              target: '_blank',
              url: '/posts/which-reverse-engineering-solution-is-right-for-me/',
              copy: globalCopy.Buttons.ReadBlog
            }
          },
          {
            category: globalCopy.Categories.Webinar,
            title: data.content.ResourceLinks.item_title_2,
            link: {
              target: '_blank',
              url: 'https://oqton.cn/news/webinars/turbine-blade-webinar/',
              copy: globalCopy.Buttons.WatchWebinar
            }
          },
          {
            category: globalCopy.Categories.Blog,
            title: data.content.ResourceLinks.item_title_3,
            link: {
              target: '_blank',
              url: '/posts/introduction-to-first-article-inspection/',
              copy: globalCopy.Buttons.ReadBlog
            }
          }
        ]}
      />
      <ArticleBlock
        Title={data.content.CustomerStories.title}
        Copy={data.content.CustomerStories.copy}
        Layout={'layout-1'}
        Link={{
          copy: data.content.CustomerStories.button_label,
          link: '/news/?&category=Customer-Story'
        }}
        Items={[
          {
            title: data.content.CustomerStories.item_title_1,
            link: '/news/customer-stories/cummins-uses-geomagic-to-get-1952-race-car-running-again/',
            image: '/assets/images/customerStories/cummins/og_image.png',
            category: globalCopy.Categories.CustomerStory,
            readTime: ''
          },
          {
            title: data.content.CustomerStories.item_title_2,
            link: '/news/customer-stories/geosciences-turn-to-geomagic-wrap-in-wake-of-disaster/',
            image: '/assets/images/customerStories/usf/og_image.png',
            category: globalCopy.Categories.CustomerStory,
            readTime: ''
          },
          {
            title: data.content.CustomerStories.item_title_3,
            link: '/news/customer-stories/kindig-it-design-builds-cars-faster-with-oqton/',
            image: '/assets/images/customerStories/kindigItDesign/IMG-1.png',
            category: globalCopy.Categories.CustomerStory,
            readTime: ''
          }
        ]}
      />

      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default ThreeDInspectionSolution;
