import React from 'react';
import { SecondaryHeading } from '../../styles/typography';

import { Main, BackgroundGradient } from '../../styles/background';
import { Content } from '../../components/layouts/PageContainer/PageContainer';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { PageContent } from '../../components/layouts/PageContent';
import { ContactCalloutWithImage } from '../../components/layouts/ContactCalloutWithImage';
import { AlignerDownloadButton } from '../../components/pageComponents/aligner/AlignerDownloadButton';
// @ts-ignore
import { ContactBlock } from '../../components/layouts/ContactBlock';
import { AlignerHeader } from '../../components/pageComponents/aligner/AlignerHeader';
import { TrustedBy } from '../../components/layouts/TrustedBy';
import { ListContainer } from '../../components/pageComponents/AIInternship/styles';
import { getForm } from '../../components/layouts/ContactModal/utils';

const Aligner = () => {
  return (
    <PageContainer>
      <HelmetContainer title="隐形正畸产线" lang={'zh-cn'} />
      <BackgroundGradient>
        <Header inverted lang={'zh-cn'} />
        <Content>
          <AlignerHeader />
        </Content>
      </BackgroundGradient>
      <Main noPadding>
        <TrustedBy
          companies={[
            { name: 'EOS', width: 123 },
            { name: 'sisma', width: 150 },
            { name: 'trumpf', width: 71 },
            { name: 'Prodways', width: 150 },
            { name: '3dsystems', width: 170 },
            { name: 'nexa3D', width: 110 },
            { name: 'eplus3d', width: 150 }
          ]}
        />
        <Content>
          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>3D打印工艺全自动CAM数据准备</SecondaryHeading>}
            text={
              <p>
                <ListContainer>
                  <li>
                    将人工智能学习应用于编程，Oqton
                    支持设计文件的病例订单化智能管理，无需工艺工程师，即可获得可打印切片文件，乃至一键发送至设备开始生产。数据处理能力包括：自动文件导入和修复、零件摆放、加强镂空、打标、高利用率排版及智能切片。
                  </li>
                  <li>自动化的数据准备直接节省了关键操作人员的时间。</li>
                  <li>
                    独创的全自动、自支撑的镂空结构计算，及全自动、高效率排版功能，有效减少材料消耗，最大限度提高3D打印设备生产力。
                  </li>
                </ListContainer>
              </p>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/aligner/demo_dental.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
          />
          <PageContent
            align="right"
            title={
              <SecondaryHeading style={{ marginTop: '40px' }}>
                一体化制造执行系统 MES确保牙套产品可追溯性
              </SecondaryHeading>
            }
            text={
              <p>
                <ListContainer>
                  <li>
                    一个界面完成生产计划、产程管理和分析，内置动态排程算法、（无纸化）零件生产追溯报告、面板和警报。
                  </li>
                  <li>
                    追溯产品设计数据、生产动作、材料、质量检查的数字化信息，实现从产品设计至生产资源的完整数字线程，并自带修订历史记录。为正畸生产合规保驾护航，同时为先进无纸化生产改革提供了技术可能（使用二维码或NFC）。
                  </li>
                </ListContainer>
              </p>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/aligner/demo_dental_eu2.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
          />
        </Content>
        <Content>
          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '100px' }}></SecondaryHeading>}
            text={<p></p>}
            image={
              <img
                style={{ width: '600px', height: 'auto' }}
                src="/assets/images/brochures/aligner_book.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
            button={
              <AlignerDownloadButton
                title="Oqton获得 隐形正畸 领域国际品牌、及众多新兴企业的信赖。"
                buttonText="下载电子书"
                iframe={getForm('alignerDownload')}
              />
            }
          />
        </Content>
        <Content>
          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '50px' }}>正畸生产全流程管理</SecondaryHeading>}
            text={
              <>
                <p>
                  <ListContainer>
                    <li>
                      连接设计团队：当病例设计定稿后，三维文件通过集成、自动上传到 Oqton
                      生产系统，一键开始打印文件处理（修复、镂空、排版及切片），无需人工干预。
                    </li>
                    <li>Oqton 支持3D打印机器的双向通信：直接发送任务至打印机；机器的远程实时监测、视频采集等。</li>
                    <li>
                      真正的端到端生产管理系统系统，支持全产程工序的标准化管理，如热成型、自动正畸器精整加工、激光打标等。
                    </li>
                  </ListContainer>
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '520px', height: 'auto' }}
                src="/assets/images/aligner/aligner_frame.png"
                alt="Monitor Everything"
              />
            }
          />
        </Content>
        {/* <FullWidthImageCallout
          alignLeft
          invert
          image={
            <img
              src="/assets/images/healthcare/Health-tracability.png"
              alt="Full traceability, planning and scheduling  "
              height="512"
            />
          }
          title="Full traceability, planning and scheduling  "
          text={
            <>
              <FullWidthImageCalloutParagraph invert>
                Regulations and certifications such as the EU Medical Device Regulation and ISO 13485 require end-to-end
                traceability of machines, people, and material, at every step of the dental workflow.
              </FullWidthImageCalloutParagraph>
              <FullWidthImageCalloutParagraph invert>
                With Oqton, you get full transparency and traceability across the production workflow through
                manufacturing execution system (MES) and machine monitoring (IoT) capabilities. This supports scheduling
                and planning for your workforce, machines and material, across multiple production sites. The machine
                monitoring capability also helps to prevent issues and to react quickly to machine down time, with
                easy-to-view live status reports.
              </FullWidthImageCalloutParagraph>
            </>
          }
        /> */}
        {/* <Content>
          <CustomerStories
            title="Our clients in the spotlight"
            customerStories={[
              {
                image: '/assets/images/customerStories/teamziereis/img-1.png',
                link: '/news/customer-stories/a-mind-blowing-step-forward/',
                company: 'TeamZiereis',
                title: 'A “mind-blowing step forward” thanks to Oqton'
              },
              {
                image: '/assets/images/customerStories/volkmer/img-1.png',
                link: '/news/customer-stories/supporting-innovation-for-future/',
                company: 'Dental-Labor Volkmer',
                title: 'Supporting innovation for future'
              },
              {
                image: '/assets/images/customerStories/crownCrema/img-1.png',
                link: '/news/customer-stories/insane-cost-and-productivity-improvements',
                company: 'Crown Ceram',
                title: '“Insane” cost and productivity improvements'
              }
            ]}
          />
        </Content> */}
        <ContactBlock title="让Oqton为您打造自动化、可追溯的正畸牙套生产线" vertical="aligner" />
      </Main>
      <ContactCalloutWithImage
        title="关注Oqton 堃腾智能制造"
        subTitle="扫码关注公众号，获取更多智造前沿资讯"
        image="/assets/images/contact/stayintouch.png"
        tertiary
      />
      <Footer lang={'zh-cn'} />
    </PageContainer>
  );
};

export default Aligner;
