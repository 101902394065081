import React from 'react';
import { getForm } from '../../components/layouts/ContactModal/utils';
import {
  OverlayContainer,
  ContactModalContainer,
  ModalTitle,
  TitleBorder,
  IframeContainer
} from '../../components/layouts/ContactModal/ContactModal';

const AmphyonContactModal = () => {
  return (
    <OverlayContainer>
      <ContactModalContainer id="testing">
        {/* <CloseContainer onClick={handleClose}>
          <Icon name="Cross" />
        </CloseContainer> */}
        <ModalTitle>免费试用</ModalTitle>
        <TitleBorder />
        <IframeContainer vertical="amphyon">{getForm('amphyon')}</IframeContainer>
      </ContactModalContainer>
    </OverlayContainer>
  );
};

export default AmphyonContactModal;
