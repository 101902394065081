import React from 'react';

import DownloadTemplate from '../../templates/DownloadTemplateCX';

const DownloadTemplateCX = () => {
  const Lang = 'zh-cn';

  return <DownloadTemplate Lang={Lang} />;
};

export default DownloadTemplateCX;
