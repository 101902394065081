import React from 'react';
import { SecondaryHeading } from '../../styles/typography';

import { Main, BackgroundGradient } from '../../styles/background';
import { Content } from '../../components/layouts/PageContainer/PageContainer';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { PageContent } from '../../components/layouts/PageContent';
import { ContactCalloutWithImage } from '../../components/layouts/ContactCalloutWithImage';
import { IoTDownloadButton } from '../../components/pageComponents/iot/IoTDownloadButton';
// @ts-ignore
import { ContactBlock } from '../../components/layouts/ContactBlock';
import { IotHeader } from '../../components/pageComponents/iot/IoTHeader';
import { TrustedBy } from '../../components/layouts/TrustedBy';
import { ListContainer } from '../../components/pageComponents/AIInternship/styles';
import { getForm } from '../../components/layouts/ContactModal/utils';

const iot = () => {
  return (
    <PageContainer>
      <HelmetContainer title="物联网工厂" lang={'zh-cn'} />
      <BackgroundGradient>
        <Header inverted lang={'zh-cn'} />
        <Content>
          <IotHeader />
        </Content>
      </BackgroundGradient>
      <Main noPadding>
        <TrustedBy
          companies={[
            { name: 'EOS', width: 123 },
            { name: 'sisma', width: 150 },
            { name: 'trumpf', width: 71 },
            { name: 'Prodways', width: 150 },
            { name: '3dsystems', width: 170 },
            { name: 'nexa3D', width: 110 },
            { name: 'eplus3d', width: 150 }
          ]}
        />
        <Content>
          <PageContent
            align="right"
            title={
              <SecondaryHeading style={{ marginTop: '40px' }}>工业数据采集系统 极速部署互联互通新车间</SecondaryHeading>
            }
            text={
              <p>
                <ListContainer>
                  <li>支持25+品牌机床设备的数据采集器。</li>
                  <li>I/O数据采集模块，超越品牌限制，无痛机床数据采集。</li>
                  <li>通过SOC2数据安全认证，及时响应工信部《物联网基础安全标准体系建设指南（2021版）》。</li>
                </ListContainer>
              </p>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/iot/iot_1.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
          />
          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>远程可视化生产 确保产线正常运行</SecondaryHeading>}
            text={
              <p>
                <ListContainer>
                  <li>实时生产状态远程监控，确保产线正常运行。</li>
                  <li>实时警报、通知系统协助团队及时响应，为减少设备停机时间保驾护航。</li>
                </ListContainer>
              </p>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/healthcare/demo_dental_2.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
          />
        </Content>
        <Content>
          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '100px' }}></SecondaryHeading>}
            text={<p></p>}
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/brochures/iotDownloadBtn.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
            button={
              <IoTDownloadButton
                title="Oqton为 数字化齿科 提供一站式智能生产解决方案！"
                buttonText="下载电子书"
                iframe={getForm('iotDownload')}
              />
            }
          />
        </Content>
        <Content>
          <PageContent
            align="right"
            title={
              <SecondaryHeading style={{ marginTop: '50px' }}>全面掌握生产情况 让数据驱动业务增长</SecondaryHeading>
            }
            text={
              <>
                <p>
                  <ListContainer>
                    <li>内嵌OEE、稼动率、吞吐量、生产交付周期等制造管理系数，产线效能全知道。</li>
                    <li>结合生产运营数据，释放工业物联网的商业价值。</li>
                  </ListContainer>
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '520px', height: 'auto' }}
                src="/assets/images/iot/iot_2.png"
                alt="Monitor Everything"
              />
            }
          />
        </Content>
        {/* <FullWidthImageCallout
          alignLeft
          invert
          image={
            <img
              src="/assets/images/healthcare/Health-tracability.png"
              alt="Full traceability, planning and scheduling  "
              height="512"
            />
          }
          title="Full traceability, planning and scheduling  "
          text={
            <>
              <FullWidthImageCalloutParagraph invert>
                Regulations and certifications such as the EU Medical Device Regulation and ISO 13485 require end-to-end
                traceability of machines, people, and material, at every step of the dental workflow.
              </FullWidthImageCalloutParagraph>
              <FullWidthImageCalloutParagraph invert>
                With Oqton, you get full transparency and traceability across the production workflow through
                manufacturing execution system (MES) and machine monitoring (IoT) capabilities. This supports scheduling
                and planning for your workforce, machines and material, across multiple production sites. The machine
                monitoring capability also helps to prevent issues and to react quickly to machine down time, with
                easy-to-view live status reports.
              </FullWidthImageCalloutParagraph>
            </>
          }
        /> */}
        {/* <Content>
          <CustomerStories
            title="Our clients in the spotlight"
            customerStories={[
              {
                image: '/assets/images/customerStories/teamziereis/img-1.png',
                link: '/news/customer-stories/a-mind-blowing-step-forward/',
                company: 'TeamZiereis',
                title: 'A “mind-blowing step forward” thanks to Oqton'
              },
              {
                image: '/assets/images/customerStories/volkmer/img-1.png',
                link: '/news/customer-stories/supporting-innovation-for-future/',
                company: 'Dental-Labor Volkmer',
                title: 'Supporting innovation for future'
              },
              {
                image: '/assets/images/customerStories/crownCrema/img-1.png',
                link: '/news/customer-stories/insane-cost-and-productivity-improvements',
                company: 'Crown Ceram',
                title: '“Insane” cost and productivity improvements'
              }
            ]}
          />
        </Content> */}
        <ContactBlock title="见证Oqton智联网AIoT机床云系统升级您的车间产能" vertical="healthcare" />
      </Main>
      <ContactCalloutWithImage
        title="关注Oqton 堃腾智能制造"
        subTitle="扫码关注公众号，获取更多智造前沿资讯"
        image="/assets/images/contact/stayintouch.png"
        tertiary
      />
      <Footer lang={'zh-cn'} />
    </PageContainer>
  );
};
export default iot;
