import styled from 'styled-components';

import { colors, media, spacing } from '../../foundations';
import { Tag, HeadingL, HeadingM } from '../../../styles/typography';

export const ContactBlockContainer = styled.article`
  display: grid;
  grid-template-rows: repeat(2, auto);
  padding: ${spacing.xxxxl}px 0 ${spacing.xxxxl}px;
  margin: 0 auto;
  max-width: 1130px;
  border-radius: ${spacing.xs}px;
  gap: 30px 0;
  text-align: center;

  @media screen and (max-width: ${media.xl}px) {
    width: auto;
    margin: 0 60px;
  }

  @media screen and (max-width: ${media.lg}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);

    padding: 60px 30px;
    margin: 0 30px;
  }

  @media screen and (max-width: ${media.md}px) {
  }
`;

export const Title = styled.h1`
  ${HeadingL}
  max-width: 910px;
  margin: 0 auto;
  text-align: center;
  @media screen and (max-width: ${media.md}px) {
    ${HeadingM}
  }
`;

export const ContactHeading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${Tag}
  color: ${colors.secondaryAccent}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
