import React, { useState } from 'react';
import {
  HeaderContainer,
  Title,
  IntroText,
  SubTitle,
  HeaderTitleContainer,
  HeaderButton,
  HeaderImageContainer
} from '../../subComponents/HeaderStyles';
import { ContactModal } from '../../layouts/ContactModal/ContactModal';

export const OnDemandManufacturingHeader = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <HeaderContainer>
        <HeaderTitleContainer>
          <SubTitle>实现工业转型，赢取市场先机</SubTitle>
          <Title>规模化按需定制</Title>
          <IntroText>
            <p></p>
            <a
              href="https://zh.wikipedia.org/wiki/%E5%B7%A5%E6%A5%AD4.0"
              className="inline"
              rel="noopener noreferrer nofollow"
            >
              工业4.0
            </a>
            自2011年首次提出以来，
            <a
              href="https://www.forbes.com/sites/bernardmarr/2020/05/04/here-are-the-top-10-technology-trends-of-the-4th-industrial-revolution/?sh=214debd11fbe"
              className="inline"
              rel="noopener noreferrer nofollow"
            >
              相关技术
            </a>
            不断发展，按需定制、
            <a
              href="https://zh.wikipedia.org/wiki/%E6%99%BA%E8%83%BD%E8%A3%BD%E9%80%A0"
              className="inline"
              rel="noopener noreferrer nofollow"
            >
              智能生产
            </a>
            逐步成为可能，并被认为是制造企业全新的发展蓝海与方向。
            <p>
              要抓住业务机遇，制造企业不但要进行技术变革，运营模式也要与时俱进，除了成本、质量等传统的生产要素，
              <a
                href="https://www.mckinsey.com.cn/%E7%81%AF%E5%A1%94%E5%B7%A5%E5%8E%82%E5%BC%95%E9%A2%86%E6%9C%AA%E6%9D%A5%E5%88%B6%E9%80%A0%E4%B8%9A/"
                className="inline"
                rel="noopener noreferrer nofollow"
              >
                客户体验提升、价值链效率优化
              </a>
              等也将成为重要竞争因素。
            </p>
          </IntroText>
          <HeaderButton onClick={() => setOpenModal(true)}>预约演示</HeaderButton>
        </HeaderTitleContainer>
        <HeaderImageContainer>
          <img
            src="/assets/verticals/ondemandmanufacturing/3d_systems_figure_4_materials_guide_collage_1.png"
            width="100%"
            alt="OnDemandManufacturing Header"
          />
        </HeaderImageContainer>
      </HeaderContainer>
      {openModal && <ContactModal handleClose={() => setOpenModal(false)} vertical="OnDemandManufacturing" />}
    </>
  );
};
