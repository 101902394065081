import React from 'react';
import { MaxContentContainer } from '../../subComponents/ContentContainer';
import { Article } from './Article';
import { BackgroundContainer, Container, ButtonContainer } from './styles';

export interface ArticleProps {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content: React.ReactNode;
  button?: React.ReactNode;
  buttonLink?: string;
  image?: { src: string; alt: string; imagePos?: 'left' | 'right'; width?: number; height?: number };
  mediaComponent?: { component: React.ReactNode; pos: 'left' | 'right' };
}

interface MediaComponentProps {
  articles: ArticleProps[];
  inverted?: boolean;
  decreasedPadding?: boolean;
  id?: string;
}

export const MediaComponentV2 = ({ articles, inverted, decreasedPadding, id }: MediaComponentProps) => {
  return (
    <BackgroundContainer inverted={inverted} id={id}>
      <MaxContentContainer>
        <Container decreasedPadding={decreasedPadding}>
          {articles.map((article, index) => {
            return <Article {...article} key={`article-${index}`} />;
          })}
        </Container>
      </MaxContentContainer>
      <ButtonContainer></ButtonContainer>
    </BackgroundContainer>
  );
};
