import styled from 'styled-components';
import {
  colors,
  media,
  spacing,
  newLetterSpacing as letterSpacing,
  newFontWeights as fontWeights
} from '../foundations';
import { Paragraph, ParagraphBold } from '../../styles/typography';

export const HeaderContainer = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: ${spacing.xxxxl}px;
  padding-bottom: ${spacing.xxxl}px;

  @media screen and (max-width: ${media['lg+']}px) {
    grid-template-columns: 1fr;
    width: auto;
    padding-top: ${spacing.xl}px;
    padding-bottom: ${spacing.xl}px;
  }
`;

export const HeaderImageContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  @media screen and (max-width: ${media['lg+']}px) {
    display: none;
  }
`;

export const HeaderTitleContainer = styled.article`
  display: flex;
  flex-direction: column;
  padding-right: ${spacing.xxl}px;
  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const Title = styled.h1`
  color: ${colors.primary};
  font-family: 'centrano2', sans-serif;
  font-size: 40px;
  font-weight: ${fontWeights.medium};
  line-height: 1.2;
  letter-spacing: ${letterSpacing.m};
  color: ${colors.primary};
`;

export const SubTitle = styled.h2`
  font-family: 'centrano2', sans-serif;
  font-size: 36px;
  font-weight: ${fontWeights.book};
  line-height: 1.2;
  letter-spacing: ${letterSpacing.m};
  color: ${colors.primary};
  margin-bottom: ${spacing.l}px;
`;

export const IntroHeader = styled(ParagraphBold)`
  color: ${colors.primary};
  margin-bottom: ${spacing.l}px;
  margin-top: ${spacing.xl}px;
  @media screen and (max-width: ${media.md}px) {
    margin-bottom: ${spacing.m}px;
    margin-top: ${spacing.m}px;
  }
`;

export const IntroText = styled(Paragraph)`
  color: ${colors.primary};

  margin-bottom: ${spacing.xl}px;
  @media screen and (max-width: ${media.md}px) {
    margin-bottom: ${spacing.m}px;
  }
`;

export const HeaderRightContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: ${spacing.l}px;
`;

export const HeaderVideoContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding-top: 57%;
  height: 310px;
  border: '8px solid white';
  borderradius: '14px';

  @media screen and (max-width: ${media.md}px) {
    margin-top: 24px;
    height: 240px;
  }

  @media screen and (max-width: ${media['xl']}px) {
    height: 260px;
  }
`;

export const HomeHeaderRightContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const HeaderDivider = styled.div`
  display: flex;
  width: 59px;
  border-bottom: 2px solid ${colors.teal};
`;

export const HeaderButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: fit-content;

  margin-bottom: ${spacing.xl}px;
  padding: 0 ${spacing.l}px;

  letter-spacing: ${letterSpacing.xxl};
  color: ${colors.primary};

  border-radius: 4px;
  background-color: ${colors.teal};

  cursor: pointer;
`;
