import styled from 'styled-components';
import { Tag } from '../../../styles/typography';
import { colors, media, spacing } from '../../foundations';

export const TrustedByContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 auto ${spacing.xxxxl}px auto;
  padding-top: ${spacing.xxxl}px;

  width: calc(100vw - 90px);
  min-width: 1200px;
  max-width: 1400px;

  border-radius: 8px;

  @media screen and (max-width: ${media.xxl}px) {
    width: 1130px;
    min-width: unset;
    max-width: unset;
  }

  @media screen and (max-width: ${media.xl}px) {
    width: 80%;
    height: auto;
  }
`;

export const TrustedByTitle = styled.h3`
  ${Tag};

  margin: 0 auto ${spacing.xl}px auto;
  color: ${colors.tertiary};
`;

export const LogoContainer = styled.ul`
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  flex-wrap: wrap;

  max-width: 1130px;
`;

export const Logo = styled.li<{ smallerWidth?: boolean; smallPadding?: boolean }>`
  width: ${({ smallerWidth }) => (smallerWidth ? `140px` : `150px`)};
  height: 120px;

  padding: 0 ${p => (p.smallPadding ? '30px' : '40px')};

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${media['lg+']}px) {
    width: 150px;
  }
`;
