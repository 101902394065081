import React from 'react';
import { Vertical } from '../../../../utils/types';

export const getForm = (vertical: Vertical) => {
  switch (vertical) {
    case 'v2-manufacturing-os':
      return (
        <>
          <iframe
            title="Manufacturing OS Form"
            src="https://go.oqton.com/l/976933/2023-08-01/bql97"
            width="100%"
            height="100%"
            allowTransparency
          />
        </>
      );
    case 'build-quality':
      return (
        <>
          <iframe
            title="Build-quality"
            src="https://go.oqton.com/l/976933/2023-10-20/bz9qf"
            width="100%"
            height="100%"
            allowTransparency
          />
        </>
      );
    case 'v2-additive-manufacturing':
      return (
        <>
          <iframe
            title="Additive Manufacturing Form"
            src="https://go.oqton.com/l/976933/2023-07-21/bpslj"
            width="100%"
            height="100%"
            allowTransparency
          />
        </>
      );
    case 'v2-medical':
      return (
        <>
          <iframe
            title="Medical Form"
            src="https://go.oqton.com/l/976933/2023-07-21/bpslm"
            width="100%"
            height="100%"
            allowTransparency
          />
        </>
      );
    case 'NewHealthcareForm':
      return (
        <>
          <iframe
            title="Healthcare Form"
            src="https://go.oqton.com/l/976933/2022-04-28/223s"
            width="100%"
            height="100%"
            allowTransparency
          />
        </>
      );
    case 'additive':
      return (
        <iframe
          title="Additive Demo Form"
          src="https://go.oqton.com/l/976933/2022-04-18/rw3"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'HealthcareBrochure':
      return (
        <iframe
          title="Dental Brochure"
          src="https://go.oqton.com/l/976933/2023-03-07/bd65c"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'healthcare':
      return (
        <iframe
          title="Healthcare Demo Form"
          src="https://go.oqton.com/l/976933/2022-04-28/223s"
          width="100%"
          height="100%"
          allowTransparency
        />
      );

    case 'welding':
      return (
        <iframe
          title="Welding Demo Form"
          src="https://go.oqton.com/l/976933/2022-04-28/223d"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'demo':
      return (
        <iframe
          title="Generic Demo Form"
          src="https://go.oqton.com/l/976933/2022-04-28/223w"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'mos':
      return null;
    case 'wrap':
      return (
        <iframe
          title="Wrap Form"
          src="https://go.oqton.com/l/976933/2022-06-15/d7lp"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'controlX':
      return (
        <iframe
          title="Control X Form"
          src="https://go.oqton.com/l/976933/2022-06-15/d7ld"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'controlXJa':
      return (
        <iframe
          title="Control X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr81"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'controlXKo':
      return (
        <iframe
          title="Control X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr7x"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'controlXFr':
      return (
        <iframe
          title="Control X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr77"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'controlXDe':
      return (
        <iframe
          title="Control X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr74"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'controlXAutomation':
      return (
        <iframe
          title="Control X Automation Form"
          src="https://go.oqton.com/l/976933/2022-06-15/d7ld"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'designX':
      return (
        <iframe
          title="Design X Form"
          src="https://go.oqton.com/l/976933/2022-06-15/d7hz"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'designXJa':
      return (
        <iframe
          title="Design X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr1q"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'designXKo':
      return (
        <iframe
          title="Design X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr1m"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'designXFr':
      return (
        <iframe
          title="Design X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr1f"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'designXDe':
      return (
        <iframe
          title="Design X Form"
          src="https://go.oqton.com/l/976933/2022-11-24/8nr1j"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'GeomagicForSolidworks':
      return (
        <iframe
          title="Geomagic For Solidworks Form"
          src="https://go.oqton.com/l/976933/2022-06-28/p8jf"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'GeomagicEssentials':
      return (
        <iframe
          title="Plan your demo"
          src="https://go.oqton.com/l/976933/2022-08-17/3jct2"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'GeomagicDesignXEssentials':
      return (
        <iframe
          title="Request Free Trial"
          src="https://go.oqton.com/l/976933/2022-08-16/3hdc8"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'GeomagicControlXEssentials':
      return (
        <iframe
          title="Request Free Trial"
          src="https://go.oqton.com/l/976933/2022-08-16/3hddr"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'freeform':
      return (
        <iframe
          title="Freeform Demo Form"
          src="https://go.oqton.com/l/976933/2022-05-03/265d"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'd2p':
      return (
        <iframe
          title="D2P Demo Form"
          src="https://go.oqton.com/l/976933/2022-05-03/2653i"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case '3dXpert':
      return (
        <iframe
          title="3DXpert Demo Form"
          src="https://go.oqton.com/l/976933/2022-05-03/25vd"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'amphyon':
      return (
        <iframe
          title="Amphyon Demo Form"
          src="https://go.oqton.com/l/976933/2022-05-03/265h"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'home':
      return null;
    case 'buyDesignX':
      return (
        <iframe
          title="Buy Geomagic Design X"
          src="https://go.oqton.com/l/976933/2022-05-13/2kqg"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'buyWrap':
      return (
        <iframe
          title="Buy Geomagic Wrap Form"
          src="https://go.oqton.com/l/976933/2022-05-11/2j65"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'buyFreeform':
      return (
        <iframe
          title="Buy Freeform Form"
          src="https://go.oqton.com/l/976933/2022-05-11/2j5y"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'buyControlX':
      return (
        <iframe
          title="Buy ControlX Form"
          src="https://go.oqton.com/l/976933/2022-05-11/2j68"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'buy3DXpert':
      return (
        <iframe
          title="Buy 3DXpert Form"
          src="https://go.oqton.com/l/976933/2022-05-16/2lkr"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'buyD2P':
      return (
        <iframe
          title="Buy D2P Form"
          src="https://go.oqton.com/l/976933/2022-05-03/2653i"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'trialDesignX':
      return (
        <iframe
          title="Trial Design X Form"
          src="https://go.oqton.com/l/976933/2022-06-28/p8jf"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
    case 'default':
    default:
      return (
        <iframe
          title="Contact Us Form"
          src="https://go.oqton.com/l/976933/2022-04-28/223w"
          width="100%"
          height="100%"
          allowTransparency
        />
      );
  }
};
