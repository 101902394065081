import styled from 'styled-components';
import { RDH2, RDPL, RDButton, RDSubtitle } from '../../../styles/typography';
import { colorsRD, media, spacing } from '../../foundations';
import { Link } from '../../subComponents/Link';

export const BackgroundContainer = styled.div<{ inverted?: boolean }>`
  ${({ inverted }) => (inverted ? `background-image: ${colorsRD.gradients.primary};` : '')};
  color: ${({ inverted }) => (inverted ? colorsRD.white : colorsRD.primary)};
`;

export const Container = styled.section<{ inverted?: boolean; decreasedPadding?: boolean }>`
  padding: ${p => (p.decreasedPadding ? `${spacing.xxxl}px ${spacing.xxxxxl}px` : `${spacing.xxxxxl}px;`)};

  @media screen and (max-width: ${media.xl}px) {
    padding: ${spacing.xxxl}px 0;
  }
`;

export const ArticleContainer = styled.article<{ imagePos?: 'left' | 'right' }>`
  display: flex;
  justify-content: center;
  gap: ${spacing.xxxxxl}px;
  flex-direction: ${({ imagePos }) => (imagePos === 'left' ? 'row-reverse' : 'row')};

  margin-top: ${spacing.xl}px;

  @media screen and (max-width: ${media['xxl++']}px) {
    padding: 0 ${spacing.m}px;
  }

  @media screen and (max-width: ${media['xxl+']}px) {
    gap: ${spacing.xxxl}px;
  }

  @media screen and (max-width: ${media.xl}px) {
    flex-direction: column-reverse;
    gap: ${spacing.xl}px;
  }
`;

export const ArticleContent = styled.div`
  width: 700px;
  align-self: center;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const ArticleTitle = styled.h3`
  ${RDH2};

  margin-bottom: ${spacing.l}px;
`;

export const ArticleText = styled.p`
  ${RDPL};
`;

export const Subtitle = styled.sub`
  ${RDSubtitle};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${spacing.l}px 0;

  @media screen and (max-width: ${media.md}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Button = styled.button`
  background-color: inherit;
  color: ${colorsRD.primary};
  ${RDButton};

  padding: ${spacing.s}px ${spacing['m+']}px;
  border: 1px solid ${colorsRD.primary};
  cursor: pointer;
`;

export const WhiteButton = styled.button`
  grid-area: button;

  background-color: ${colorsRD.white};
  color: ${colorsRD.black};

  ${RDButton};

  padding: ${spacing.s}px ${spacing['m+']}px;
  border: ${spacing.xxxs}px solid ${colorsRD.accent.fossilGrey100};
  border-radius: ${spacing.xxs}px;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  margin-top: ${spacing['m+']}px;

  a {
    ${RDButton};
  }
`;

export const ArticleImage = styled.img<{ width?: number }>`
  width: ${p => (p.width ? `${p.width}px` : `100%`)};
`;
