import React from 'react';

import AutomotiveTemplate from './../templates/AutomotiveTemplate';

const Automotive = () => {
  const Lang = 'zh-cn';

  return <AutomotiveTemplate Lang={Lang} />;
};

export default Automotive;
