import React from 'react';
import { getForm } from '../../components/layouts/ContactModal/utils';
import {
  OverlayContainer,
  ContactModalContainer,
  ModalTitle,
  TitleBorder,
  IframeContainer
} from '../../components/layouts/ContactModal/ContactModal';

import { HelmetContainer } from '../../components/pageComponents/helmet';

const ThreeDXpertContactModal = () => {
  return (
    <OverlayContainer>
      <HelmetContainer
        image="/assets/images/pages/3DXpert/hero.png"
        title="3DXpert"
        titleTag="免费试用"
        pageDescription="3DXpert 免费试用申请 | Developed by Oqton"
        description="3DXpert 免费试用申请 | Developed by Oqton"
      />
      <ContactModalContainer id="testing">
        {/* <CloseContainer onClick={handleClose}>
          <Icon name="Cross" />
        </CloseContainer> */}
        <ModalTitle>免费试用</ModalTitle>
        <TitleBorder />
        <IframeContainer vertical="3dXpert">{getForm('3dXpert')}</IframeContainer>
      </ContactModalContainer>
    </OverlayContainer>
  );
};

export default ThreeDXpertContactModal;
