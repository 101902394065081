import React from 'react';

import { Link } from '../../subComponents/Link';
import { Title, Sub, ContentSection, ContentTitle, ContentText, ContentLink } from './PrivacyStatementStyles';

export const PrivacyStatementContainer = () => {
  return (
    <section>
      <Title>Privacy Statement</Title>
      <Sub>We value our interactions with you and take your privacy seriously.</Sub>
      <ContentSection>
        <ContentTitle>
          Oqton is committed to respecting the privacy rights of those who use our platform and who visit us in person
          and on the web.
        </ContentTitle>
        <ContentText>
          We’d like to keep in touch with you about the work we’re doing and how we can work together to transform
          manufacturing. As a startup, we’re invested in making sure what we’re doing is effective, and we count on
          anonymized analytics data from cookies to help guide our way. If you enable cookies and/or give us your
          contact information you can update your privacy options on this page at any time.
        </ContentText>
        <ContentText>
          Click here to read the full text of our&nbsp;
          <Link link="/privacy-policy">
            <ContentLink>Privacy Policy</ContentLink>
          </Link>
          .
        </ContentText>
      </ContentSection>
    </section>
  );
};
