import styled from 'styled-components';

import { colors, media, spacing, getFontSize, getLetterSpacing } from '../../foundations';
import { HeadingM, ParagraphBoldStyle } from '../../../styles/typography';

export const ContactCalloutContainer = styled.article`
  display: grid;
  grid-template-columns: 480px 1fr;
  margin: 0 auto;
  max-width: 1130px;
  background-color: ${colors.primary};
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.07);
  border-radius: ${spacing.xs}px;
  @media screen and (max-width: ${media.xl}px) {
    width: auto;
    margin: 0 ${spacing.xxl}px;
  }

  @media screen and (max-width: ${media.lg}px) {
    grid-template-columns: 1fr;
    padding: ${spacing.l}px ${spacing.xl}px 0;
    margin: 0 ${spacing.xl}px;
  }

  @media screen and (max-width: ${media.md}px) {
  }
`;

export const Title = styled.h1`
  ${HeadingM};
`;

export const SubTitle = styled.h3`
  ${ParagraphBoldStyle};
`;

export const FormContent = styled.div`
  padding: ${spacing.l}px ${spacing.l}px 0 ${spacing.xxxl}px;
`;

export const TermsText = styled.p`
  font-size: ${getFontSize('xs')};
  line-height: 1.2;
  letter-spacing: ${getLetterSpacing('s')};
  color: ${colors.quinaryAccent};
  margin-bottom: ${spacing.xxxl}px;
`;

export const ImageContainer = styled.div<{ src: string }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${spacing.xxxxl}px;
  background-image: url(${p => p.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px 0 0 8px;
  @media screen and (max-width: ${media.lg}px) {
    display: none;
  }
`;

export const FormContainer = styled.iframe`
  height: 300px;
  @media screen and (max-width: ${media.md}px) {
    height: 350px;
  }
`;
