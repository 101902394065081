import React, { useState } from 'react';
import styled from 'styled-components';
import { Box } from '../../containers/Box';
import { spacing } from '../../foundations';
import { Icon } from '../../subComponents/Icon';
import { BrochureModal } from '../../layouts/BrochureModal/BrochureModal';
// import { PrimaryHeading } from '../../../styles/typography';
import { SecondaryHeading } from '../../../styles/typography';
import {
  ButtonText,
  SingleDownloadButton,
  SingleDownloadButtonWrapper
} from '../../layouts/DownloadButtons/DownloadButtonsStyles';
import { ListContainer } from '../AIInternship/styles';

export const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface IoTDownloadButtonProps {
  title: string;
  buttonText?: string;
  form?: string;
  iframe?: React.ReactNode;
}

export const IoTDownloadButton = ({ title, buttonText, form, iframe }: IoTDownloadButtonProps) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <SingleDownloadButtonWrapper>
        <Box ml={spacing.xxxl} mt={spacing.xxxxxl}>
          <Box ml={spacing.xxxl} mb={spacing.xl}>
            {/* <PrimaryHeading>{`${title}`}</PrimaryHeading> */}
            <SecondaryHeading>{`${title}`}</SecondaryHeading>
            <p>
              <ListContainer>
                <li>数据采集</li>
                <li>建模分析</li>
                <li>行动干预</li>
              </ListContainer>
            </p>
          </Box>
          <SingleDownloadButton onClick={() => setOpenModal(true)}>
            <Icon name="Download" mr={spacing.s} />
            <ButtonText>{buttonText}</ButtonText>
          </SingleDownloadButton>
        </Box>
        {/* <img src={`/assets/images/brochures/${image}.png`} alt={`${title} brochure`} /> */}
      </SingleDownloadButtonWrapper>
      {openModal && (
        <BrochureModal title={`下载 ${title}`} form={form} iframe={iframe} handleClose={() => setOpenModal(false)} />
      )}
    </>
  );
};
