import styled from 'styled-components';
import { HeadingXS, subStyle } from '../../../styles/typography';
import { colors, spacing, typography } from '../../foundations';
import { Content } from '../../layouts/PageContainer/PageContainer';

export const Title = styled.h1`
  grid-area: title;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.medium};
  line-height: 1;

  margin: ${spacing.xxxxl}px 0 ${spacing.xxl}px 0;
`;

export const SubSection = styled.div`
  grid-area: metadataContainer;

  display: flex;
  flex-direction: row;

  margin-top: ${spacing.xxl}px;
  margin-bottom: 120px;
  padding-top: ${spacing.l}px;

  max-width: 360px;
  border-top: solid 2px ${colors.quaternaryAccent};

  line-height: 2;

  align-items: center;
  height: 30px;
`;

export const SubTitle = styled.p`
  display: inline-block;

  ${subStyle};
  color: ${colors.quaternaryAccent};

  &:after {
    margin: -2px 8px 0 8px;
    height: 3px;
    width: 3px;
    content: '';
    display: inline-block;
    border-radius: 2px;
    background-color: ${colors.quinary};
    vertical-align: middle;
  }
`;

export const Date = styled.p`
  color: ${colors.quinary};

  font-size: ${typography.fontSizes.s}px;
  font-weight: ${typography.fontWeights.medium};
  letter-spacing: 2.4px;
`;

export const ContentContainer = styled(Content)`
  margin-top: ${spacing.xxxxl}px;
`;

export const OutroLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const ImageContainer = styled.img`
  margin: ${spacing.xxl}px 0;
`;

export const QuoteContainer = styled.div`
  margin-top: ${spacing.xxxxl}px;
`;

export const ListContainer = styled.ul`
  margin-top: ${spacing.l}px;
  margin-left: ${spacing.l}px;
  list-style: outside;
  line-height: ${spacing.xl}px;
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LinkContainer = styled.a``;

export const ContactButton = styled.button`
  ${HeadingXS};
  border: none;
  border-radius: ${spacing.xxl}px;
  padding: ${spacing.l}px ${spacing.xxl}px;
  background-color: ${colors.secondaryAccent};
  margin-bottom: ${spacing.xxxxl}px;
  color: ${colors.primary};
  cursor: pointer;
`;
