import React from 'react';

import { Flex } from '../../containers/Flex';
import { Icon } from '../../subComponents/Icon';

import {
  ArticleContainer,
  ArticleContent,
  ArticleImage,
  ArticleTitle,
  ArticleText,
  Subtitle,
  Button,
  StyledLink
} from './styles';
import { ArticleProps } from './MediaComponentV2';

export const Article = ({ title, content, image, mediaComponent, subTitle, button, buttonLink }: ArticleProps) => {
  const position = mediaComponent?.pos ?? (image ? image?.imagePos ?? 'left' : undefined) ?? undefined;

  return (
    <ArticleContainer imagePos={position}>
      <ArticleContent>
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
        {title && <ArticleTitle>{title}</ArticleTitle>}
        {content && <ArticleText>{content}</ArticleText>}
        {button && (
          <StyledLink link={buttonLink}>
            <Button>
              {button} <Icon ml="12px" name="Arrow" />
            </Button>
          </StyledLink>
        )}
      </ArticleContent>
      {(image || mediaComponent) && (
        <Flex justifyContent="center" alignItems="center" flex="auto">
          {mediaComponent ? mediaComponent.component : <ArticleImage {...image} />}
        </Flex>
      )}
    </ArticleContainer>
  );
};
