import React from 'react';
import { Link } from 'gatsby';
import { Footer } from '../components/layouts/Footer';
import { Header } from '../components/layouts/HeaderComponent';
import { Hero } from '../components/layouts/Hero';
import { HeaderBackground } from '../components/pageComponents/header';
import { HelmetContainer } from '../components/pageComponents/helmet';
import { MediaComponentV2 } from '../components/V2/MediaComponentV2';
import { ArticleContent } from '../components/pageComponents/iamIntroPackages';
import { Resources } from '../components/layouts/Resources';
import { FullWidthImageCallout } from '../components/layouts/FullWidthImageCallout/FullWidthImageCallout';
import { FullWidthImageCalloutParagraph } from '../components/layouts/FullWidthImageCallout/FullWidthImageCalloutStyles';
import { BackgroundGradient } from '../styles/background';
import { ContactCalloutWithImage } from '../components/layouts/ContactCalloutWithImage';
import { ContactBlock } from '../components/layouts/ContactBlock';
import { MediaComponent } from '../components/layouts/MediaComponent';
import { PageContainer } from '../components/containers/PageContainer';
// import {
//   TrustedByContainer,
//   TrustedByTitle,
//   TrustedByList
// } from '../components/layouts/Hero/styles';

export const Gmp = () => {
  const lang = 'zh-cn';

  return (
    <PageContainer lang={lang}>
      <HelmetContainer
        lang={lang}
        image="/assets/images/solutions/3d-scanning-solutions-key-visual.webp"
        title="Geomagic许可维护计划"
        titleTag="Oqton"
        pageDescription="利用完善的软件维护和支持计划，确保您的团队始终使用世界前沿技术。"
        description="利用完善的软件维护和支持计划，确保您的团队始终使用世界前沿技术。"
      />
      <HeaderBackground>
        <Header lang={lang} />
        <Hero
          title={
            <React.Fragment>
              Geomagic
              <br />
              许可维护计划
            </React.Fragment>
          }
          description="利用完善的软件维护和支持计划，确保您的团队始终使用世界前沿技术。"
          image={{
            src: '/assets/images/solutions/3d-scanning-solutions-key-visual.webp',
            alt: 'Geomagic许可证维护升级'
          }}
          vertical="default"
          phone="TRUE"
        />
      </HeaderBackground>

      {/* <TextColumns
        Columns={'1'}
        Copy={
          '<p>三维扫描是将现实世界中的物理对象转换为精确数字模型的过程，正在被各行各业以各种形式使用。<br/><br/>将基于三维扫描的技术集成到生产环境中，通过快速数字化、造型优化和准确的质量保证，Geomagic 软件方案 协助制造企业提升产品制造竞争力。</p>'
        }
        Title={`发挥三维扫描技术的无限潜力`}
        // Logos={['geomagic-designx', 'geomagic-controlx', 'geomagic-wrap']}
      /> */}

      <BackgroundGradient>
        <FullWidthImageCallout
          alignLeft
          image={<img src="/assets/images/pages/Geomagic icons.png" alt="支持全球主要品牌设备" height="40" />}
          title="保护您的投资"
          text={
            <>
              <FullWidthImageCalloutParagraph>
                Geomagic 维护计划是一项一体化保护计划，旨在确保您的公司始终使用最新、最高效的 Geomagic
                软件版本，并且您始终可以优先获得及时和称职的技术支持。
                此外，还提供延长保修计划（额外收费），为您的触觉设备提供完整的硬件保护。 Geomagic
                维护计划是数字链中的关键环节，旨在帮助您在日益复杂的全球市场中保持生产力和竞争力。
              </FullWidthImageCalloutParagraph>
            </>
          }
        />
      </BackgroundGradient>

      {/* <MediaComponentWithCenterImage
        // inverted
        articles={[
          {
            title: "发挥三维扫描技术的无限潜力",
            subTitle: "发挥三维扫描技术的无限潜力",
            image: {
              src: '/assets/images/solutions/3d-scanning-solutions-key-visual.webp',
              alt: "发挥三维扫描技术的无限潜力",
              imagePos: 'mid'
            },
            content: <React.Fragment>Geomagic许可维护计划</React.Fragment>,
            button: '了解更多',
            buttonLink: '/manufacturing-os',
            width: '80%'
          }
        ]}
      /> */}

      {/* <AdditivePageContent
        title="发挥三维扫描技术的无限潜力"
        descriptionText={
          <p>
            三维扫描是将现实世界中的物理对象转换为精确数字模型的过程，正在被各行各业以各种形式使用。
            <br />
            将基于三维扫描的技术集成到生产环境中，通过快速数字化、造型优化和准确的质量保证，Geomagic 软件方案
            协助制造企业提升产品制造竞争力。
          </p>
        }
      /> */}
      <MediaComponentV2
        articles={[
          {
            title: '利用专家团队协助您的团队',
            image: {
              src: '/assets/images/solutions/3d-reverse-engineering-key-visual.webp',
              alt: '逆向工程｜Oqton',
              imagePos: 'right'
            },
            content: (
              <ArticleContent>
                <a
                  target="_blank"
                  className="inline"
                  href="https://softwaresupport.oqton.com/s/article/Contact-Us?language=en_US"
                  rel="noopener noreferrer nofollow"
                >
                  Geomagic 技术支持团队
                </a>
                通过首选技术支持服务帮助您保持生产力和效率。通过与我们技术支持团队的独特联系，您将永远不会陷入困境，并且您将通过电话、网络会议或数据协作系统享受一流的一对一帮助。
                <Link to="/solution/3d-reverse-engineering">逆向工程</Link>
                Geomagic 维护计划允许访问我们的专家技术支持团队，他们可以协助：
                <br />
                <br />
                <li>许可/安装和故障排除</li>
                <li>工具功能/实施</li>
                <li>脚本/自动化协助</li>
                <li>增强请求权限</li>
                <li>
                  引导访问{' '}
                  <a
                    target="_blank"
                    className="inline"
                    href="https://softwaresupport.oqton.com/s/?language=en_US"
                    rel="noopener noreferrer nofollow"
                  >
                    1,000 多篇知识库文章/视频
                  </a>
                </li>
                <li>技术应用咨询——确保您充分利用Geomagic 软件，我们可以帮助您改善性能和工作流程！</li>
                <br />
                除了现有优势之外，具有有效维护的客户可以每年一次免费申请他们拥有的软件的第二个席位（许可证类型在线激活），最多
                4 周，以弥补最终的短缺。
              </ArticleContent>
            ),
            button: '联系支持团队',
            buttonLink: 'https://softwaresupport.oqton.com/s/article/Contact-Us?language=en_US'
          }
        ]}
      />

      <MediaComponent
        articles={[
          {
            title: <React.Fragment>保持在最前沿</React.Fragment>,
            image: {
              src: '/assets/images/solutions/3d-reverse-engineering-key-visual.webp',
              alt: '逆向工程｜Oqton',
              imagePos: 'right'
            },
            content: (
              <ArticleContent>
                我们的工程师不断努力改进我们的软件，并且在我们发布升级时，您将免费获得所有更新和改进的完全访问权限。这些更新包括错误修复、用户请求的增强功能、新版本发布和新插件。
                通过访问最新的工具和技术，在您的行业中保持竞争力。这些新特性和功能对于让您的软件在最佳状态下运行是必不可少的。
                Geomagic 维护计划有助于确保顺畅且不间断的工作流程。
              </ArticleContent>
            ),
            mediaComponent: {
              component: (
                <iframe
                  title="Stay on the Cutting Edge"
                  className="vidyard_iframe"
                  src="//play.vidyard.com/YAgvNBeJ47pLW2kYNGpBty.html?"
                  width="640"
                  height="250"
                  scrolling="no"
                  frameBorder="0"
                  allowTransparency
                  allowFullScreen
                />
              ),
              pos: 'left'
            }
          }
        ]}
      />

      <MediaComponentV2
        articles={[
          {
            title: '我们同样支持您的设备',
            image: {
              src: '/assets/images/pages/Geomagic with Touch X.png',
              alt: '我们同样支持您的设备',
              imagePos: 'right'
            },
            content: (
              <ArticleContent>
                如果您的{' '}
                <a
                  target="_blank"
                  className="inline"
                  href="https://cn.3dsystems.com/haptics?_ga=2.251396934.1761128873.1684835193-930747485.1684835191"
                  rel="noopener noreferrer nofollow"
                >
                  3D Systems力反馈设备
                </a>
                也有问题，我们可以提供帮助。 保修计划涵盖正常磨损。为了加快大规模维修的周转时间，高级更换计划适用于
                Touch 和 Touch X 触觉设备。有关覆盖范围的更多详细信息，请咨询您的 Geomagic Solutions 销售代表。
              </ArticleContent>
            ),
            button: '了解更多',
            buttonLink: '/contact-us/'
          }
        ]}
      />

      <Resources
        title="相关资源"
        description="课程、电子书及研讨会"
        resources={[
          {
            title: 'Control X 2023精彩发布, 领略自动化检测的魅力',
            label: 'Webinar',
            link: '/news/webinars/control-x-2023-release-automated-inspection/'
          },
          {
            title: 'Geomagic Design X 经典培训录像',
            label: 'Blog',
            link: '/news/blog/geomagic-design-x-basic-training-recording-1/'
          },
          {
            title: '逆向工程中的 3D 模型快速指南',
            label: 'Blog',
            link: '/news/blog/a-guide-to-3d-models-in-reverse-engineering/'
          }
        ]}
      />
      <ContactBlock title="抓住产品创新与先进制造的机遇" vertical="default" />
      <ContactCalloutWithImage
        title="关注Oqton 堃腾智能制造"
        subTitle="扫码关注公众号，获取更多智造前沿资讯"
        image="/assets/images/contact/stayintouch.png"
      />

      <Footer />
    </PageContainer>
  );
};

export default Gmp;
