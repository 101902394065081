import React from 'react';
import styled from 'styled-components';
import { colors, newLetterSpacing as letterSpacing, typography, spacing } from '../foundations';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.secondaryAccent};
  width: fit-content;
  height: fit-content;
  padding: 0 ${spacing.l}px;
  border-radius: 4px;
  color: ${colors.primary};
  letter-spacing: ${letterSpacing.xxl};
  height: 40px;
  font-weight: ${typography.fontWeights.book};
  cursor: pointer;
`;

export type CallToActionButtonProps = {
  onClick?: (e: React.SyntheticEvent<EventTarget>) => void;
  text?: string;
};

export const CallToActionButton = ({ text, onClick }: CallToActionButtonProps) => {
  return <Button onClick={onClick}>{text}</Button>;
};
