import React from 'react';

import { TermsOfUseContentContainer, Title } from './TermsOfUseStyles';

export const TermsOfUseContainer = () => {
  return (
    <TermsOfUseContentContainer>
      <Title>Last Updated May 3, 2018</Title>
      <p>
        The www.oqton.com website (“Site”) is operated by Oqton, Inc. or its affiliated companies or subsidiaries
        (collectively, “Oqton” or “us” or “we”). These Terms of Use and any additional terms or conditions that we post
        on the Site from time-to-time (“Terms of Use”) set forth the terms and conditions under which you may enter and
        use the Site and any services available on the Site which may include, but are not limited to, the ability to
        create a user profile and submit user generated content to the Site (collectively the “Services”). Registration
        to use the Services or some features of the Site may be necessary. As part of the registration process, you may
        be required to select a user name and password, which may require that you provide a screen name and/or a valid
        email address (“User ID”). Please read the Terms of Use carefully. By entering, accessing, or using the Site (or
        registering with the Site), you agree to the Terms of Use without limitation or qualification. If you do not
        agree to the Terms of Use (without limitation or qualification), you are not authorized to view or use the Site,
        and you must exit this Site, and cease all access to and use of the Site. The Terms of Use govern your access to
        and use of the Site regardless of the manner in which you access the Site (internet, mobile network, etc.).
      </p>
      <p>
        BY USING THE SITE, OR BY ACCESSING AND USING THE SERVICES, YOU ARE ACCEPTING AND AGREEING TO THESE TERMS ON
        BEHALF OF YOURSELF OR THE ENTITY YOU REPRESENT IN CONNECTION WITH THE ACCESS AND USE. YOU REPRESENT AND WARRANT
        THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ACCEPT AND AGREE TO THESE TERMS ON BEHALF OF YOURSELF OR THE
        ENTITY YOU REPRESENT. YOU REPRESENT THAT YOU ARE OF SUFFICIENT LEGAL AGE IN YOUR JURISDICTION OR RESIDENCE TO
        USE OR ACCESS THE SITE OR SERVICES AND TO ENTER INTO THIS AGREEMENT.
      </p>
      <p>
        These Terms of Use state the legally binding terms with respect to your use of the Site. Oqton reserves the
        right to modify or change the Terms of Use at any time without prior notice to you. Such modification or change
        shall be effective upon posting on the Site. If Oqton revises the Terms of Use, it will also revise the “Last
        Updated” date at the top of this page. The most current version of the Terms of Use can be reviewed by clicking
        on the “TERMS” or “TERMS OF USE” hypertext link located at the bottom of the main page of the Site. Your
        continued entry to and/or use of the Site after Oqton posts any revised Terms of Use constitutes your agreement
        to any such revised Terms of Use.
      </p>
      <p>1. General Use and Restrictions.</p>
      <p>
        1.1. Use of Site. Conditioned upon, and subject to, your strict compliance with the Terms of Use, Oqton grants
        you a personal, non-exclusive, non-transferable, limited license to enter and use the Site to view information,
        use the Services and for other purposes expressly permitted by Oqton in writing on the Site.
      </p>
      <p>
        You agree not to enter or use the Site for any purpose that is prohibited by the Terms of Use or is otherwise
        unlawful. You further agree to comply with all applicable U.S. laws, statutes, ordinances, regulations,
        contracts and applicable licenses regarding your use of the Site. Oqton may, in its sole discretion, terminate
        or suspend your entry to, and/or use of, the Site, or any portion thereof, at any time, with or without notice
        and for any reason (or no reason), and you agree that (a) if your authorization to enter the Site is terminated,
        you will not thereafter enter, or attempt to enter, the Site, directly or indirectly, and (b) if your
        authorization to enter the Site is suspended, you will not thereafter enter, or attempt to enter, the Site,
        directly or indirectly, until your suspension is removed and Oqton gives you express notice thereof.
      </p>
      <p>
        1.2. Information Provided. If you provide any information to us, you agree to provide only true, accurate,
        current and complete information. Use of this Site is governed by our Privacy Policy which we encourage you to
        review by clicking here.
      </p>
      <p>
        1.3. Use of Content. You may print or copy any information displayed or transmitted on the Site (collectively,
        “Content”) that you are authorized to access, solely for informational and non-commercial, personal use;
        provided that you (a) do not remove any title, trademark, copyright and/or restricted rights notices contained
        on such Content, and (b) strictly comply with the provisions of the Terms of Use including, without limitation,
        Section 1.4 below.
      </p>
      <p>
        1.4. Restrictions. Except as provided in Section 1.3 above, you may not (and you agree that you will not)
        reproduce, alter, modify, create derivative works, or publicly display any Content without first receiving
        Oqton’s express written permission. You further agree not to, without first obtaining Oqton’s express written
        permission, (a) use any of its brands, trademarks or service marks as metatags on other web sites, (b) use the
        Site in any manner that is illegal or impairs the operation of the Site or its availability or usage by others,
        and/or (c) display any part of the Site in frames (or any Content via in-line links). You further agree not to
        decompile, reverse engineer or disassemble any software or other products or processes accessible through the
        Site, and not to insert any code or product or manipulate the Site in any way that affects the user’s experience
        including, without limitation, taking any action that imposes, or may impose, an unreasonable or
        disproportionately large load on the Site. You further agree not to use any data mining, web crawlers, robots,
        cancelbots, spiders, Trojan horses, or any data gathering or extraction method in connection with your use of
        the Site except for customary search engines used in accordance with automated instructions directed to search
        engines and available on the Site.
      </p>
      <p>2. General.</p>
      <p>2.1 Account and Password.</p>
      <p>
        If we issue an account to use the Services to you (an “Account”), we will also issue a username and/or initial
        password for such Account to you (each user of the Service that is issued an Account shall be referred to herein
        as a “Registered User”). The portions of the Site available to the general public and to Registered Users may
        vary. You may not be able to access all features or use all Services on the Site if you do not become a
        Registered User. You may not authorize any third party to enter and/or use your Account on their behalf.
        Accordingly, you agree to protect your username and password by, among other things, keeping your username and
        password confidential. If, notwithstanding the foregoing obligation, you allow another party to use your
        username and/or password to access your Account, you will be responsible for all use by the party using your
        Account. You agree to (a) immediately notify Oqton of any unauthorized use of your password or any other breach
        of security, and (b) ensure that you exit from your Account at the end of each session.
      </p>
      <p>2.2 Submissions and User Generated Content.</p>
      <p>
        2.1. The Site may contain bulletin boards, chat groups, forums and/or other interactive areas that allow users
        to express their opinions and post information and/or other materials, e.g., for platform help and support
        (collectively, “User Generated Content”). We do not monitor all of the User Generated Content posted or
        transmitted by users.
      </p>
      <p>
        2.2. Any opinions, advice, statements, services, offers, or other information or content expressed or made
        available by third party users are those of the third party and not of Oqton. Oqton does not represent or
        endorse the accuracy or reliability of any User Generated Content displayed, uploaded, or distributed on the
        Site by any third party. You acknowledge that any reliance upon any such third party User Generated Content is
        at your sole risk.
      </p>
      <p>
        2.3. Oqton may, in its sole discretion, edit and/or remove any User Generated Content you submit to the Site.
        Without limiting the generality of the foregoing, Oqton may edit or remove any User Generated Content you submit
        to the Site that Oqton, in its sole discretion, deems abusive, defamatory, obscene, unlawful, or otherwise
        unacceptable or in violation of these Terms of Use, and you waive any moral rights you may have with respect to
        changes in the User Generated Content. We are not responsible for maintaining your posted User Generated Content
        and we may, in our sole discretion, delete or destroy it at any time with no liability or obligation to you.
      </p>
      <p>
        2.4. You are solely responsible for any User Generated Content you submit to the Site. We shall not be liable
        for the use or misuse of any information or data, including personal information that is included in any User
        Generated Content that you submit to the Site.
      </p>
      <p>
        2.5. By submitting User Generated Content to the Site, you hereby grant to Oqton, its affiliates and related
        entities, a royalty-free, unrestricted, worldwide, perpetual, irrevocable, non-exclusive and fully transferable,
        assignable and sublicensable right and license to use, copy, exploit, modify, archive, store, reproduce, adapt,
        publish, translate, create derivative works from, distribute, perform and display all such User Generated
        Content in any form, media, software or technology of any kind now existing or developed in the future. You
        further grant Oqton, its affiliates, related entities, licensees and assignees the right to use your name and
        any other information about you that you provide in connection with any such use of the User Generated Content.
        You understand and agree that visitors to the Site may use any User Generated Content you submit to the Site in
        accordance with these Terms of Use. User Generated Content submitted by you to this Site can be used according
        to these Terms of Use without compensation or acknowledgement to you.
      </p>
      <p>3. User Generated Content Limitations.</p>
      <p>
        3.1. You agree not to post User Generated Content that: i) contains vulgar, profane, abusive or hateful
        language, epithets or slurs, text or illustrations in poor taste, inflammatory attacks of a personal, racial or
        religious nature, or expressions of bigotry, racism, discrimination or hate; or ii) is defamatory, threatening,
        disparaging, inflammatory, false, misleading, deceptive, fraudulent, inaccurate, unfair, contains gross
        exaggeration or unsubstantiated claims, is unreasonably harmful or offensive to any individual or community,
        contains any actionable statement, or tends to mislead or reflect unfairly on any other person, business or
        entity; or iii) violates or infringes any right of Oqton or any third party, including without limitation any
        privacy, trademark, copyright or other intellectual property right, or will otherwise cause injury to any third
        party, or iv) discriminates on the grounds of race, religion, national origin, gender, age, marital status,
        sexual orientation or disability, or refers to such matters in any manner prohibited by law; or v) violates any
        municipal, state or federal law, rule, regulation or ordinance, or attempts to encourage evasion or violation
        thereof; or vi) unfairly interferes with any third party’s uninterrupted use and enjoyment of the Site; or vii)
        advertises, promotes or offers to trade any goods or services, except in areas specifically designated for such
        purpose; or viii) contains copyrighted or other proprietary material of any kind on the Site without the express
        permission of the owner of that material; or ix) includes or transmits viruses or other harmful, disruptive or
        destructive files; or x) disrupts, interferes with, or otherwise harms or violates the security of the Site, or
        any Services, system resources, accounts, passwords, servers or networks connected to or accessible through the
        Site or affiliated or linked sites; or xi) “flames” any individual or entity (e.g., sends repeated messages
        related to another user and/or makes derogatory or offensive comments about another individual), or repeats
        prior posting of the same message under multiple threads or subjects; or xii) otherwise violates these Terms of
        Use.
      </p>
      <p>
        3.2. Any User Generated Content posted by you or others will be deemed not to be confidential or secret. You
        understand that personal and other information (e.g., username, e-mail address, phone number) that you post on
        our Site is generally accessible to, and may be collected and used by others, and may result in unsolicited
        messages or other contact from others. To protect your safety, please use your best judgment when posting
        information. We particularly discourage divulging personal phone numbers and addresses or other information that
        can be used to identify or locate you.
      </p>
      <p>4. Linking and Third Party Dealings.</p>
      <p>
        4.1. Links to External Sites. Oqton may provide hyperlinks to other web sites and Internet resources operated by
        parties other than Oqton. Oqton has no control over such sites and resources or their privacy policies or terms
        of use and Oqton’s policies and terms no longer govern. Such hyperlinks are provided for your reference only.
        The inclusion of hyperlinks to such web sites does not imply any sponsorship, affiliation or endorsement of the
        material on such web sites or with their operators.
      </p>
      <p>
        4.2. Linking to the Site. Subject to the further provisions of this Section 4, Oqton welcomes links to the Site
        from other web sites. However, if Oqton demands that you not link to the Site, or any portion of the Site, you
        agree that you will not, directly or indirectly, link to the Site or such portion of the Site as directed in our
        demand, at any time after such demand is made.
      </p>
      <p>5. Intellectual Property.</p>
      <p>
        5.1 General. Except for Content that is in the public domain, the Site and all Content, as well as the selection
        and arrangement of the Content, is owned by (or licensed to) Oqton or its suppliers and is protected by
        copyright, trade dress, trademark, unfair competition, and/or other laws and may not be used, copied or imitated
        in whole or in part except as expressly provided herein. Except as otherwise expressly provided in the Terms of
        Use, all rights in and to the Site and Content are expressly reserved by Oqton.
      </p>
      <p>
        5.2 Trademarks. The brands, logos, tradenames, trademarks (both registered and common law) and service marks
        (both registered and common law) are the property of Oqton, its affiliates, and their respective licensors. Not
        all brands, logos, tradenames, trademarks and service marks of Oqton and its affiliates appear on this Site.
        Except as expressly provided in Section 1.3 above, none of the foregoing may be copied, imitated or used, in
        whole or in part, without Oqton’s prior written permission. In addition, all page headers, custom graphics,
        button icons, and scripts are service marks, trademarks and/or trade dress of Oqton or its licensors and may not
        be copied, imitated, or used, in whole or in part, without Oqton’s prior written permission.
      </p>
      <p>6. Dispute Resolution And Arbitration/Class Action Waiver/Jury Trial Waiver.</p>
      <p>
        6.1 Arbitration. Oqton and you agree to arbitrate all disputes and claims that arise from or relate to these
        Terms, the Site, or the Services in any way, except for claims arising from bodily injury. THIS ARBITRATION IS
        MANDATORY AND NOT PERMISSIVE. This agreement to arbitrate is intended to be broadly interpreted, including, for
        example:
      </p>
      <p>
        claims arising out of or relating to any aspect of the relationship between us that is created by or involves
        these Terms, the Site, or the Services, regardless of the legal theory; claims for mental or emotional distress
        or other emotional/mental injury arising from the relationship between us; claims that arose before you accepted
        these Terms (such as claims related to disclosures or the marketing of the Services or the process for seeking
        approval to use the Services); claims that may arise after the termination of your use of the Site or the
        Services or any agreement between us; and claims brought by or against our respective subsidiaries, parent
        companies, members, affiliates, as well as the respective officers, directors, employees, agents, predecessors,
        successors, and assigns of these entities, you, and Oqton. This arbitration agreement does not preclude either
        of us from bringing an individualized action in small claims court. It also does not preclude either of us from
        seeking an individualized preliminary injunction or temporary restraining order, pending arbitration, in any
        court that has jurisdiction. Nor does this arbitration agreement bar you from bringing issues to the attention
        of federal, state, or local agencies. Such agencies can, if the law allows, seek relief against us on your
        behalf. In addition, you or Oqton may seek injunctive or other equitable relief to protect your or its trade
        secrets and intellectual property rights or to prevent loss or damage to its services in any court with
        competent jurisdiction.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE, LAW, YOU UNDERSTAND AND AGREE THAT WE ARE EACH (A) WAIVING THE
        RIGHT TO A TRIAL BY JURY; (B) WAIVING THE RIGHT TO PARTICIPATE IN A CLASS OR REPRESENTATIVE ACTION; AND (C)
        WAIVING THE RIGHT TO CLAIM OR RECOVER PUNITIVE DAMAGES AGAINST THE OTHER. These Terms evidence a transaction in
        interstate commerce, and thus the Federal Arbitration Act governs the interpretation and enforcement of this
        arbitration provision.
      </p>
      <p>
        6.2 Notice of Disputes. If either of us intends to seek arbitration of a dispute, that party must provide the
        other with notice in writing. The notice to Oqton should be sent to: Oqton Legal Department, 832 Sansome Street,
        4thFloor, San Francisco, CA 94111. Oqton will send notice to you at the e‑mail and/or mailing addresses
        associated with your account. Your notice to Oqton must (a) provide your name, mailing address, and email
        address; (b) describe the dispute; and (c) state the relief you are requesting. If we are unable to reach an
        agreement to resolve the dispute within 60 days after the notice is received, you or we may commence
        arbitration.
      </p>
      <p>
        6.3 Arbitration Procedures. The arbitration will be governed by the Consumer Arbitration Rules (“JAMS Rules”) of
        JAMS (“JAMS”), as modified by these Terms, and will be administered by JAMS. The JAMS Rules are available online
        at www.jamsadr.com. If JAMS is unavailable, the parties shall agree to another arbitration provider. Unless you
        and we agree otherwise or unless prohibited by applicable law, any arbitration hearings will take place in San
        Francisco County, California. All issues are for the arbitrator to decide, except that issues relating to the
        scope and enforceability of this arbitration provision or the arbitrability of disputes are for the court to
        decide. The arbitrator may consider but is not bound by rulings in other arbitrations between Oqton and Oqton
        users. The arbitrator can award the same individualized damages and relief that a court can award. Judgment on
        the award may be entered by any court having jurisdiction.
      </p>
      <p>
        6.4 Costs of Arbitration. JAMS’s fee schedule is subject to change and may be found online at www.jamsadr.com.
        If required by applicable law, Oqton will pay all JAMS filing, administrative, and arbitrator fees for any
        arbitration that Oqton commences. If, however, the arbitrator finds that either the substance of your claim or
        the relief sought is frivolous or brought for an improper purpose (as measured by the standards in Federal Rule
        of Civil Procedure 11(b)), then the payment of all JAMS fees shall be governed by the JAMS Rules. In such cases,
        the arbitrator may direct you to reimburse Oqton for amounts that Oqton paid on your behalf.
      </p>
      <p>
        6.5 NO CLASS ARBITRATION. The arbitrator may award declaratory or injunctive relief only in favor of the
        individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s
        individual claim. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AND OQTON AGREE THAT EACH MAY BRING
        CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
        PURPORTED CLASS, REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL PROCEEDING. Further, unless all affected parties
        agree otherwise, the arbitrator may not consolidate more than one person’s claims and may not otherwise preside
        over any form of a representative or class proceeding. If a court decides that applicable law precludes
        enforcement of any of this subsection’s limitations as to a particular claim for relief, then that claim (and
        only that claim) must be severed from the arbitration and may be brought in court.
      </p>
      <p>
        6.6 Future Changes To Arbitration Provision. If Oqton makes any changes to the Dispute Resolution and
        Arbitration section of these Terms (other than a change to the address at which Oqton will receive notices of
        dispute or rejections of future changes to the Dispute Resolution and Arbitration section), you may reject any
        such change by sending us written notice within 30 days of the change to us to Oqton, Inc., 832 Sansome Street,
        4thFloor, San Francisco, CA 94111 (Attn: Legal Department) in which case your account with Oqton will
        immediately be terminated, you should cease all access to and use of the Site, and this arbitration provision,
        as in effect immediately prior to the amendments you reject, will survive.
      </p>
      <p>
        7. Policy, Notices and Procedures Regarding Claims of Copyright Infringement. Oqton respects the intellectual
        property rights of others. If you believe that your work has been copied and is accessible on the Site in a way
        that constitutes copyright infringement, you may notify our agent as provided in this Section 7. Pursuant to
        Title 17, United States Code, Section 512(c)(2), notification of claimed copyright infringement should be
        directed to:
      </p>
      <p>
        By Mail:
        <br /> Oqton, Inc.
        <br /> Legal Department
        <br /> 832 Sansome Street, 4th Floor
        <br /> San Francisco, CA 94111
      </p>
      <p>By E-mail: legal@oqton.com</p>
      <p>
        NOTE: The preceding information in this Section 7 is provided exclusively for notifying Oqton that your
        copyrighted material may have been infringed. All other inquiries, such as product or service related questions
        and requests, or questions on privacy, will not receive a response through this process.
      </p>
      <p>
        8. Indemnification. You hereby agree to indemnify and hold harmless Oqton and its affiliates, officers, members,
        directors, employees, shareholders, information providers, suppliers, contractors and licensees (collectively,
        “Indemnified Parties”) from and against any and all liability and costs, including, without limitation,
        reasonable attorneys’ fees, incurred by the Indemnified Parties in connection with any claim arising out of any
        breach, or alleged breach, of any of the Terms of Use by you.
      </p>
      <p>9. Limitation of Liability.</p>
      <p>
        9.1 TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OQTON, ITS LICENSORS OR THE INDEMNIFIED
        PARTIES BE LIABLE FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
        LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS OR SERVICES OR
        ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE TERMS OF USE, THE SITE,
        SERVICES OR CONTENT. Subject to the below provision, in no event shall Oqton’s total liability to you for all
        damages exceed $500.00.
      </p>
      <p>
        9.2 NOTWITHSTANDING THE ABOVE, NOTHING IN THIS PROVISION SHALL BE UNDERSTOOD TO LIMIT OQTON’S LIABILITY FOR (1)
        PERSONAL INJURY OR DEATH (a) ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE TERMS OF USE, THE SITE, SERVICES OR
        CONTENT or (b) CAUSED BY OQTON’S OWN NEGLIGENT, INTENTIONAL OR OTHERWISE UNLAWFUL MISCONDUCT OR (2) ANY DAMAGES
        CAUSED BY OQTON’S FAILURE TO ADHERE TO COMMERCIALLY REASONABLE EFFORTS TO PROTECT YOUR PERSONAL INFORMATION.
      </p>
      <p>
        10. Modifications to the Site. For the avoidance of doubt, Oqton may modify, suspend, discontinue and/or
        restrict the use of all or any portion of the Site including, without limitation, the Services and Content, at
        any time for any reason (or for no reason) and without notice or liability.
      </p>
      <p>
        11. Governing Law and Jurisdiction. These Terms, and any claim, dispute, action, cause of action, issue, or
        request for relief arising out of or relating to these Terms or your use of the Site or the Services shall be
        governed by the laws of the State of California without giving effect to any conflict of laws principles that
        may provide the application of the law of another jurisdiction. The courts in some states and countries may not
        apply California law to some types of disputes. If you reside in one of those states or countries, then where
        California law is excluded from applying, your state’s or country’s laws will apply. TO THE FULLEST EXTENT
        PERMITTED BY APPLICABLE LAW, YOU AGREE TO SUBMIT TO THE PERSONAL JURISDICTION OF THE STATE AND FEDERAL COURTS IN
        OR FOR SAN FRANCISCO COUNTY, CALIFORNIA FOR THE PURPOSE OF LITIGATING ALL SUCH CLAIMS OR DISPUTES, UNLESS SUCH
        CLAIM OR DISPUTE IS REQUIRED TO BE ARBITRATED AS SET FORTH IN AN ABOVE SECTION.
      </p>
      <p>
        12. General Provisions. If any provision of the Terms of Use is declared or found to be illegal, unenforceable
        or void, then the invalid or unenforceable provision shall be modified in accordance with the applicable law as
        nearly as possible to reflect the original intention of the applicable provision, and the remaining provisions
        of the Terms of Use shall remain in full force and effect. Section headings are provided for convenience only,
        and shall not be read or interpreted to restrict any provision contained in the Terms. No right or remedy
        conferred by the Terms of Use is exclusive of any other right or remedy conferred herein or by law or in equity;
        rather, all of such rights and remedies are cumulative of every other such right or remedy and may be exercised
        concurrently or separately from time-to-time. Oqton may provide notice to you relating to the Terms of Use by
        sending an e-mail to your last known e-mail address, if any, your last known postal address, if any, or posting
        a notice on the Site, and any such notice shall be deemed given and received on the earlier of the day it is
        sent to you or the day it is posted on the Site. A printed version of the Terms of Use and of any notices given
        to you in electronic form shall be admissible in judicial or administrative proceedings based upon or relating
        to the Terms of Use to the same extent and subject to the same conditions as other business documents and
        records originally generated and maintained in printed form. The failure of Oqton to insist upon or enforce
        strict performance by you of any provision of the Terms of Use shall not be construed as a waiver of any
        provision or right. If Oqton brings any suit against you to enforce the Terms of Use or otherwise in connection
        with your use of and/or entry onto the Site, you agree that if Oqton prevails in such suit Oqton shall be
        entitled to recover all costs and expenses incurred in such suit including reasonable attorneys’ fees.
      </p>
    </TermsOfUseContentContainer>
  );
};
