import React from 'react';

import { FullWidthCalloutContainer, ImageContainer, ContentContainer, Title } from './FullWidthImageCalloutStyles';

interface FullWidthImageCalloutProps {
  image: React.ReactNode;
  text: React.ReactNode;
  title: string;
  invert?: boolean;
  alignLeft?: boolean;
}

export const FullWidthImageCallout = ({ image, title, text, invert, alignLeft }: FullWidthImageCalloutProps) => {
  return (
    <FullWidthCalloutContainer alignLeft={alignLeft}>
      <ImageContainer>{image}</ImageContainer>
      <ContentContainer>
        <Title invert={invert}>{title}</Title>
        {text}
      </ContentContainer>
    </FullWidthCalloutContainer>
  );
};
