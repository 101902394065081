import React, { useState } from 'react';

import { Vertical } from '../../../utils/types';
import { ContactBlockBackground } from '../../../styles/background';
import { CallToActionButton } from '../../subComponents/CallToActionButton';

import { ContactModal } from '../ContactModal/ContactModal';
import { ContactBlockContainer, Title, ContactHeading, ButtonWrapper } from './ContactBlockStyles';

interface ContactBlockProps {
  title: React.ReactNode;
  vertical?: Vertical;
  subTitle?: string;
  buttonCopy?: string;
}

export const ContactBlock = ({ title, vertical, subTitle, buttonCopy }: ContactBlockProps) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <ContactBlockBackground>
        <ContactBlockContainer>
          <ContactHeading>{subTitle ?? 'Contact'}</ContactHeading>
          <Title>{title}</Title>
          <ButtonWrapper>
            <CallToActionButton text={buttonCopy ?? 'Plan your demo'} onClick={() => setOpenModal(true)} />
          </ButtonWrapper>
        </ContactBlockContainer>
      </ContactBlockBackground>
      {openModal && <ContactModal handleClose={() => setOpenModal(false)} vertical={vertical} />}
    </>
  );
};
