import React from 'react';
import { Link } from 'gatsby';
import { Footer } from '../../components/layouts/Footer';
import { HeroButtonLess } from '../../components/layouts/Hero/HeroButtonLess';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { Button, ButtonContainer } from '../../components/pageComponents/mediaComponentContent/styles';
import { Icon } from '../../components/subComponents/Icon';
import { HeaderBackground } from '../../components/pageComponents/header';
import { Header } from '../../components/layouts/HeaderComponent';
import { MediaComponentV2 } from '../../components/V2/MediaComponentV2';
import { ArticleContent } from '../../components/pageComponents/iamIntroPackages';
import { Related } from '../../components/layouts/Related';
import { Resources } from '../../components/layouts/Resources';
import { ContactBlock } from '../../components/layouts/ContactBlock';
import { PageContainer } from '../../components/containers/PageContainer';
// import { FormContainerTaller } from '../../components/pageComponents/contact/ContactStyles';
// import { WebinarForm } from '../../components/pageComponents/news/webinars/WebinarStyles';
// import { PageContainer } from '../../components/containers/PageContainer';

const LearnGeomagicForStudentSkillsCompetition = () => {
  return (
    <PageContainer>
      <React.Fragment>
        <HelmetContainer
          image="/assets/images/solutions/3d-scanning-solutions-key-visual.webp"
          title="职业技能大赛"
          titleTag="Geomagic特训 | Oqton"
          pageDescription="从逆向建模、三维建模和缺陷修复、设计操作及数据分析检测等维度，深度挖掘Geomagic软件操作细节和应用技巧，积极备战职业技能大赛。"
          description="从逆向建模、三维建模和缺陷修复、设计操作及数据分析检测等维度，深度挖掘Geomagic软件操作细节和应用技巧，积极备战职业技能大赛。"
        />
        <HeaderBackground>
          <Header />
          <HeroButtonLess
            title="职业技能大赛"
            description="从逆向建模、三维建模和缺陷修复、设计操作及数据分析检测等维度，深度挖掘Geomagic软件操作细节和应用技巧，积极备战职业技能大赛。"
            image={{
              src: '/assets/images/solutions/3d-scanning-solutions-key-visual.webp',
              alt: 'Geomagic Design X by Oqton'
            }}
          />
        </HeaderBackground>
        <MediaComponentV2
          articles={[
            {
              title: <React.Fragment>Design X 汽车支架快速逆向建模</React.Fragment>,
              content: (
                <>
                  教程1:
                  <br></br>
                  完成汽车结构设计的建模方式有许多种，来看<Link to="/geomagic-designx">Geomagic Design X </Link>{' '}
                  便捷、快速、简单的快速建模技巧。
                  <ButtonContainer>
                    <a href="https://go.oqton.com/l/976933/2023-04-11/bgfh2" rel="opener">
                      <Button>
                        练习模型下载
                        <Icon ml="12px" name="Arrow" />
                      </Button>
                    </a>
                  </ButtonContainer>
                </>
              ),
              mediaComponent: {
                component: (
                  <iframe
                    title="Design X 汽车支架快速逆向建模"
                    className="vidyard_iframe"
                    src="//play.vidyard.com/jCeHcrgFt2udbemwWkfHLW.html?"
                    width="640"
                    height="360"
                    scrolling="no"
                    frameBorder="0"
                    allowTransparency
                    allowFullScreen
                  ></iframe>
                ),
                pos: 'right'
              },
              image: {
                src: '/assets/images/news/bracket-GeomagicDesignX.jpg',
                alt: 'Geomagic Design X 快速逆向建模汽车支架',
                imagePos: 'left'
              }
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: <React.Fragment>Design X 水泵弯头逆向建模视频讲解</React.Fragment>,
              content: (
                <>
                  教程2:
                  <br></br>
                  金砖增材制造赛项逆向模块样题难点解密！<br></br>
                  最初内表面为大圆角，跟外表面一致，但此结构无法脱模，需要用内部两圆柱相交获得，切除多余部分。<br></br>
                  来看<Link to="/geomagic-designx">Geomagic Design X </Link> 便捷、快速、简单的快速建模技巧。
                  {/* <ListContainer>
                  <li>利用软件进行劳动力密集型的任务自动化</li>
                  <li>通过保留学到的专业知识来解决不断扩大的制造业技术差距</li>
                  <li>在更短的时间内提高零件质量</li>
                  <li>更快速的回应客户请求</li>
                  <li>标准化操作，降低产品报废率</li>
                </ListContainer> */}
                  <ButtonContainer>
                    <a href="https://go.oqton.com/l/976933/2023-04-11/bgfh2" rel="opener">
                      <Button>
                        练习模型下载
                        <Icon ml="12px" name="Arrow" />
                      </Button>
                    </a>
                  </ButtonContainer>
                </>
              ),
              mediaComponent: {
                component: (
                  <iframe
                    className="vidyard_iframe"
                    title="Design X 水泵弯头逆向建模视频讲解"
                    src="//play.vidyard.com/XgG7H8qLzY2dY1PNLihxxi.html?"
                    width="640"
                    height="360"
                    scrolling="no"
                    frameBorder="0"
                    allowTransparency
                    allowFullScreen
                  ></iframe>
                ),
                pos: 'left'
              },
              image: {
                src: '/assets/images/news/pump-GeomagicDesignX.jpg',
                alt: 'Geomagic Design X 快速逆向建模水泵弯头',
                imagePos: 'left'
              }
            }
          ]}
        />
        {/* <PageContainer>
        <WebinarForm>
          <FormContainerTaller
            title="练习模型发送至邮箱"
            src="https://go.oqton.com/l/976933/2023-04-11/bgfh2"
            width="100%"
            height="100%"
          />
        </WebinarForm>
        {/* <WebinarColumns>
          <WebinarDescription>
          </WebinarDescription>
        </WebinarColumns> */}
        {/* </PageContainer> */}
        <Related
          inverted
          title=""
          articles={[
            {
              title: 'Geomagic Control X',
              description:
                '将 Geomagic Design X 与全面的检测和计量软件配合使用，在简洁直观的工作流程中，凭借行业最强大的工具获得优势。',
              image: { src: '/assets/images/pages/geomagicDesignX/controlX.png', alt: 'placeholder' },
              url: '/geomagic-controlx'
            },
            {
              title: 'Geomagic Wrap',
              description:
                '将 Geomagic Design X 与强大的扫描处理软件配合使用，并将您的 3D 扫描数据和导入的文件直接转换为 3D 模型，使其能够立即用于下游处理。',
              image: { src: '/assets/images/pages/geomagicDesignX/wrap.png', alt: 'placeholder' },
              url: '/geomagic-wrap'
            },
            {
              title: 'Geomagic Design X',
              description:
                '将 Geomagic Control X 与全面的逆向工程软件的配合使用，可以创建出可编辑、基于特征的实体模型，并与现有的 CAD 软件兼容。',
              image: { src: '/assets/images/pages/geomagicControlX/designX.png', alt: 'Geomagic Design X' },
              url: '/geomagic-designx'
            }
          ]}
        />
        {/* <WebinarColumns>
        <WebinarDescription>
          <p>
            在全球化浪潮的推动下，汽车工业的发展也逐渐国际化，加上巴西、中国等新兴汽车产地和市场的兴起，对于无论本土还是外来品牌来说都带来了新的机遇。在此情形下，借助先进3D TOUCH技术软件带来的更专业更成熟的设计与分析能力，便是突出重围的法宝利器。 
            当下成熟的汽车品牌商常会采取3D设计软件辅以油泥建模的方案来进行设计。在此之前，传统汽车造型的建模类型为Nurbs曲面建模和Sub D细分建模，这种形式会造成诸多不便。
          </p>
          <p>
            <a href="/geomagic-freeform" target="_blank" className="inline">Geomagic Freeform</a>触觉式设计系统的出现，让设计师及雕刻家在计算机上利用触觉的方式构建3D模型，得心应手地表达自己心中创意灵感及设计意图。可谓真正的马良神笔，将创意快速呈现出来。 
          </p>
          <h2>
          研讨会日程：
          <ListContainer>
              <li>技术创新：突破传统的CAD，提高艺术的可塑性</li>
              <li>助力制造：突破传统的繁琐，快速建面，加速生产</li>
              <li>精彩演示：Geomagic Freeform 操作技巧，快速上手</li>
          </ListContainer>
          演讲：
          <br />
          梁杰，Geomagic资深技术顾问
          </h2>
        </WebinarDescription>
        <WebinarForm>
          <FormContainer
            title="Webinar Form"
            src="https://go.oqton.com/l/976933/2022-09-26/58xsg"
            width="100%"
            height="100%"
          />
        </WebinarForm>
      </WebinarColumns> */}
        {/* <Statistics
        title="全方位的质量保障"
        stats={[
          { label: '通用的解决方案', value: '1' },
          { label: '相较CMM的速度提升', value: '7X' },
          { label: '节约检测成本', value: '80%' }
        ]}
        inverted
      /> */}
        <Resources
          title="阅读更多"
          description=""
          resources={[
            {
              title: '集训：2小时精通Geomagic',
              label: 'Webinar',
              link: '/news/webinars/training-learn-geomagic-for-student-skills-competition/'
            },
            {
              title: '技能大赛练习实例：Geomagic Design X 逆向建模 - 鼠标自由曲面创建和渐消面处理',
              label: 'Blog',
              link: '/news/blog/geomagic-design-x-how-to-model-a-mouse/'
            },
            {
              title: 'Design X 培训录像精选',
              label: 'Blog',
              link: '/news/blog/geomagic-design-x-basic-training-recording-1/'
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: <React.Fragment>Geomagic Design X</React.Fragment>,
              content: (
                <ArticleContent>
                  <Link to="/geomagic-designx">Geomagic Design X</Link> 从 3D 扫描数据创建准确的 CAD
                  模型，比市场上的任何其他产品都更快、更可靠。 使用 Design X
                  从现有零件中提取新的业务价值，以加速流程的可持续性。
                </ArticleContent>
              ),
              image: {
                src: '/assets/images/solutions/3d-reverse-engineering-geomagic-design-x.webp',
                alt: 'Geomagic Design X',
                imagePos: 'left'
              },
              button: '了解更多',
              buttonLink: '/geomagic-designx'
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: 'Geomagic Wrap',
              content: (
                <ArticleContent>
                  <Link to="/geomagic-wrap">Geomagic Wrap</Link> 提供从点云到 3D 模型的最经济、快速和准确的路径。
                  从复杂或自由形式的扫描数据创建完美、无懈可击的 3D 模型的理想之选。
                </ArticleContent>
              ),
              button: '了解更多',
              buttonLink: '/geomagic-wrap',
              image: {
                src: '/assets/images/solutions/3d-reverse-engineering-geomagic-wrap.webp',
                alt: 'Geomagic Wrap',
                imagePos: 'right'
              }
            }
          ]}
        />
        <MediaComponentV2
          articles={[
            {
              title: <React.Fragment>Geomagic Control X</React.Fragment>,
              content: (
                <ArticleContent>
                  <Link to="/geomagic-controlx">Geomagic Control X</Link> 是我们的专业计量软件，使您能够捕获和处理来自
                  3D 扫描仪和其他设备的数据，以测量、理解和传达检查结果。 我们正在让您更轻松地在您的生产环境中实施准确、
                  <Link to="/geomagic-controlx/automated-inspection">自动化</Link>的质量保证。
                  <br />
                  <br />
                </ArticleContent>
              ),
              button: '了解更多',
              buttonLink: '/geomagic-controlx',
              image: {
                src: '/assets/images/solutions/inspection-key-visual-v2.webp',
                alt: 'Geomagic Control X',
                imagePos: 'left'
              }
            }
          ]}
        />
        <ContactBlock title="抓住产品创新与先进制造的机遇" vertical="default" />
        <Footer />{' '}
      </React.Fragment>
    </PageContainer>
  );
};

export default LearnGeomagicForStudentSkillsCompetition;
