// ArticleContext.js
import React, { createContext, useContext } from 'react';

const ArticleContext = createContext({});

export const useArticleContext = () => {
  const context = useContext(ArticleContext);
  if (!context) {
    throw new Error('useArticleContext must be used within an ArticleContextProvider');
  }
  return context;
};

export const ArticleContextProvider = ({ children, ArticleData }) => {
  return <ArticleContext.Provider value={ArticleData}>{children}</ArticleContext.Provider>;
};
