import React from 'react';

import AerospaceTemplate from './../templates/AerospaceTemplate';

const Aerospace = () => {
  const Lang = 'zh-cn';

  return <AerospaceTemplate Lang={Lang} />;
};

export default Aerospace;
