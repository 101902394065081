import React, { useState } from 'react';
import {
  HeaderContainer,
  Title,
  // IntroHeader,
  IntroText,
  SubTitle,
  HeaderTitleContainer,
  HeaderButton,
  HeaderImageContainer
} from '../../subComponents/HeaderStyles';
import { ListContainer } from '../Styles/style';
import { ContactModal } from '../../layouts/ContactModal/ContactModal';

export const IotHeader = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <HeaderContainer>
        <HeaderTitleContainer>
          <Title>智联网AIoT机床云</Title>
          <SubTitle>Oqton Manufacturing OS, 数据驱动精密制造车间产效提升</SubTitle>
          {/* <IntroHeader>
            By leveraging AI, Oqton’s operating system automates the end-to-end workflow, from initial design to
            preparing files for production. Oqton software provides automation for data preparation, optimized support
            or pins and dense nesting.
          </IntroHeader> */}
          <IntroText>
            <ListContainer>
              <li>实时可视化车间看板。</li>
              <li>智能互联新旧设备，实时优化生产调度、实时警报，实现车间数字化升级。</li>
              <li>丰富数据助力数据分析、维护预测，提供智能化解决方案。</li>
            </ListContainer>
          </IntroText>
          <HeaderButton onClick={() => setOpenModal(true)}>预约演示</HeaderButton>
        </HeaderTitleContainer>
        <HeaderImageContainer>
          <img src="/assets/verticals/iot/iot_header_bg.png" width="100%" alt="iot Header" />
        </HeaderImageContainer>
      </HeaderContainer>
      {openModal && <ContactModal handleClose={() => setOpenModal(false)} vertical="iot" />}
    </>
  );
};
