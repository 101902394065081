import React from 'react';

import { Main, PrimaryBackground } from '../styles/background';
import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';

import { ImageContainer } from '../components/layouts/ImageContainer';
import { CareersHeader, CareersOpen, CareersValues } from '../components/pageComponents/careers';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const Careers = () => {
  const Lang = 'zh-cn';
  const data = require(`../../data/content/Pages/careers/${Lang}.json`);

  return (
    <PageContainer>
      <HelmetContainer currentPage={'careers'} title="Careers" lang={Lang} />
      <Main noPadding>
        <Header lang={Lang} />
        <Content>
          <CareersHeader content={data.content.hero} />
          <ImageContainer content={data.content.imgContainer} noMargin noBackground />
          <CareersValues content={data.content.Values} />
        </Content>
      </Main>
      <PrimaryBackground>
        <Content>
          <CareersOpen content={data.content.CareersOpen} />
        </Content>
      </PrimaryBackground>
      <Footer lang={Lang} />
    </PageContainer>
  );
};

export default Careers;
