import React from 'react';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { Main } from '../styles/background';
import { CookiePolicyContainer } from '../components/pageComponents/cookiePolicy/CookiePolicyContainer';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const CookiePolicy = () => {
  return (
    <PageContainer>
      <HelmetContainer title="Cookie Policy" />
      <Main noPadding>
        <Header />
        <Content>
          <CookiePolicyContainer />
        </Content>
      </Main>
      <Footer />
    </PageContainer>
  );
};

export default CookiePolicy;
