import React from 'react';

import AdditiveManufacturingTemplate from './../templates/AdditiveManufacturingTemplate';

const Additive = () => {
  const Lang = 'zh-cn';

  return <AdditiveManufacturingTemplate Lang={Lang} />;
};

export default Additive;
