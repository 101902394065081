import styled from 'styled-components';
import { colors, spacing, media } from '../../foundations';

import { HeadingL, Paragraph as TextParagraph } from '../../../styles/typography';

export const FullWidthCalloutContainer = styled.article<{ alignLeft?: boolean }>`
  padding: ${spacing.xxxxl}px 0;
  display: grid;
  justify-content: center;
  width: 1130px;
  grid-template-columns: 1fr 744px;
  gap: ${spacing.xxxl}px;
  margin: 0 auto;

  @media screen and (max-width: ${media['xxl++']}px) {
    grid-template-columns: 1fr 700px;
    gap: ${spacing.xl}px;

    ${({ alignLeft }) =>
      alignLeft
        ? `
    margin: unset;
    margin-left: -${spacing.xxxxl};`
        : ''};
  }

  @media screen and (max-width: ${media['xxl+']}px) {
    grid-template-columns: 1fr 600px;
    padding-right: ${spacing.xl}px;
  }

  @media screen and (max-width: ${media.xxl}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0 auto;
  }

  @media screen and (max-width: ${media.xl}px) {
    width: 1000px;
  }
  @media screen and (max-width: ${media.lg}px) {
    width: calc(100% - 50px);
    grid-template-rows: min-content auto;
  }
  @media screen and (max-width: ${media.md}px) {
    width: 100%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: ${media.xxl}px) {
    width: 700px;
    justify-content: center;
    margin: 0 auto;
  }
  @media screen and (max-width: ${media.md}px) {
    width: auto;
    padding: 0 ${spacing.xl}px;
    margin: 0 auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${media.md}px) {
    img {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }
`;

export const Title = styled.h1<{ invert?: boolean }>`
  ${HeadingL};
  margin-bottom: 25px;
  color: ${p => (p.invert ? colors.secondary : colors.primary)};
`;

export const FullWidthImageCalloutParagraph = styled(TextParagraph)<{ invert?: boolean }>`
  color: ${p => (p.invert ? colors.secondary : colors.primary)};

  padding-bottom: 16px;
`;
