import styled from 'styled-components';
import { Anchor } from '../../../styles/typography';
import { colors, spacing, media } from '../../foundations';

export const SingleDownloadButtonWrapper = styled.article`
  background-image: linear-gradient(to top, ${colors.tertiaryBackground} 0 75%, transparent 25% 0%);

  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
`;

export const SingleDownloadButton = styled.button`
  display: flex;

  height: min-content;
  padding: ${spacing.s}px ${spacing.l}px ${spacing.xs}px ${spacing.l}px;

  border: 1px solid ${colors.secondary};
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
`;

export const DownloadButtonsContainer = styled.div`
  width: 100%;
`;

export const DownloadButtonWrapper = styled.div`
  color: ${colors.secondary};
  display: flex;
  justify-content: center;
  gap: 22px;

  @media screen and (max-width: ${media.xl}px) {
    justify-content: flex-start;
  }
  flex-wrap: wrap;
`;

export const DownloadButtonHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.xl}px;
  margin-top: ${spacing.xxxxl}px;
  text-align: center;
`;

export const DownloadButtonComponent = styled.button`
  height: 327px;
  width: 200px;
  margin-bottom: ${spacing.l}px;

  border: none;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
`;

export const ButtonText = styled.p`
  ${Anchor};
  color: ${colors.secondary};
`;
