import React from 'react';

import { ContactCalloutBackground, ContactCalloutBackgroundTertiary } from '../../../styles/background';
import { Box } from '../../containers/Box';
import { spacing } from '../../foundations';
import {
  ContactCalloutContainer,
  Title,
  ImageContainer,
  FormContent,
  FormContainer,
  SubTitle
} from './ContactCalloutWithImageStyles';

interface ContactCalloutWithImageProps {
  title: React.ReactNode;
  image: string;
  subTitle: string;
  tertiary?: boolean;
}

export const ContactCalloutWithImage = ({ title, subTitle, image, tertiary }: ContactCalloutWithImageProps) => {
  const Bg = tertiary ? ContactCalloutBackgroundTertiary : ContactCalloutBackground;
  return (
    <Bg>
      <ContactCalloutContainer>
        <ImageContainer src={image} />
        <FormContent>
          <Box pl={`${spacing.xs}px`}>
            <Title>{title}</Title>
            <SubTitle>{subTitle}</SubTitle>
          </Box>
          {/* Doesn't load on gatsby develop, you have to build and serve. */}
          <FormContainer src="/assets/hubspot/stayInTouch.html" width="100%" height="100%" title="form" />
        </FormContent>
      </ContactCalloutContainer>
    </Bg>
  );
};
