import React from 'react';
import { getForm } from '../../components/layouts/ContactModal/utils';
import {
  OverlayContainer,
  ContactModalContainer,
  ModalTitle,
  TitleBorder,
  IframeContainer
} from '../../components/layouts/ContactModal/ContactModal';
import { HelmetContainer } from '../../components/pageComponents/helmet';

const FreeformContactModal = () => {
  return (
    <OverlayContainer>
      <HelmetContainer
        image="/assets/images/pages/geomagicFreeform/hero.png"
        title="Geomagic Freeform"
        titleTag="免费试用"
        pageDescription="Geomagic Freeform 免费试用申请 | Developed by Oqton"
        description="Geomagic Freeform 免费试用申请 | Developed by Oqton"
      />
      <ContactModalContainer id="testing">
        {/* <CloseContainer onClick={handleClose}>
          <Icon name="Cross" />
        </CloseContainer> */}
        <ModalTitle>免费试用</ModalTitle>
        <TitleBorder />
        <IframeContainer vertical="freeform">{getForm('freeform')}</IframeContainer>
      </ContactModalContainer>
    </OverlayContainer>
  );
};

export default FreeformContactModal;
