import React, { useEffect } from 'react';
import styled from 'styled-components';
import { HeadingS } from '../../../styles/typography';
import { colors, spacing, media } from '../../foundations';
import { Icon } from '../../subComponents/Icon';

const OverlayContainer = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #00000040;
`;

const ContactModalContainer = styled.section`
  padding: ${spacing.xl}px ${spacing.xl}px ${spacing.xxxl}px ${spacing.xl}px;
  border-radius: ${spacing.xs}px;
  background-color: ${colors.primary};
  max-width: 500px;
  height: 600px;
  margin: ${spacing.xxl}px auto;
  position: relative;

  @media (max-width: ${media.lg}px) {
    margin: ${spacing.xxl}px ${spacing.xxxxl}px;
  }

  @media (max-width: ${media.md}px) {
    height: 700px;
    margin: ${spacing.xxl}px ${spacing.xxl}px;
  }
`;

const ModalTitle = styled.h2`
  ${HeadingS};

  margin-bottom: ${spacing.l}px;
`;

const TitleBorder = styled.div`
  width: ${spacing.xxxxl}px;
  border-bottom: 2px solid ${colors.accent};
  margin-bottom: ${spacing.xl}px;
`;

const CloseContainer = styled.div`
  padding: ${spacing.m}px;

  cursor: pointer;

  position: absolute;
  top: ${spacing.m}px;
  right: ${spacing.l}px;
`;

const IframeContainer = styled.div`
  height: 100%;
  padding-bottom: ${spacing.xxl}px;
`;

export const BrochureModal = ({
  handleClose,
  title,
  form,
  iframe
}: {
  handleClose: () => void;
  title: string;
  form?: string;
  iframe?: React.ReactNode;
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <OverlayContainer
      id="overlayContainer"
      onClick={e => {
        if ((e.target as HTMLElement).id !== 'overlayContainer') return;
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        handleClose();
      }}
    >
      <ContactModalContainer id="testing">
        <CloseContainer onClick={handleClose}>
          <Icon name="Cross" />
        </CloseContainer>
        <ModalTitle>{title}</ModalTitle>
        <TitleBorder />
        {form && <iframe src={form} width="100%" height="100%" title="Contact form" />}
        {iframe && <IframeContainer>{iframe}</IframeContainer>}
      </ContactModalContainer>
    </OverlayContainer>
  );
};
