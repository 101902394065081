import React, { useState } from 'react';
import {
  HeaderContainer,
  Title,
  // IntroHeader,
  IntroText,
  SubTitle,
  HeaderTitleContainer,
  HeaderButton,
  HeaderImageContainer
} from '../../subComponents/HeaderStyles';
import { ListContainer } from '../../pageComponents/Styles/style';
import { ContactModal } from '../../layouts/ContactModal/ContactModal';
export const AlignerHeader = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <HeaderContainer>
        <HeaderTitleContainer>
          <Title>隐形正畸牙套产线</Title>
          <SubTitle>工艺自动化、生产可追溯的智能生产管理系统</SubTitle>
          {/* <IntroHeader>
            By leveraging AI, Oqton’s operating system automates the end-to-end workflow, from initial design to
            preparing files for production. Oqton software provides automation for data preparation, optimized support
            or pins and dense nesting.
          </IntroHeader> */}
          <IntroText>
            <ListContainer>
              <li>为矫治模型的3D打印工艺提供真正意义上的自动化数据编程准备。</li>
              <li>完整的生产管理模块，从病例生产设计到交付，确保完全的生产可追溯性。</li>
              <li>深度支持正畸生产线的产程设计，支持从备料、加工、后期处理到分装等步骤。</li>
            </ListContainer>
          </IntroText>
          <HeaderButton onClick={() => setOpenModal(true)}>预约演示</HeaderButton>
        </HeaderTitleContainer>
        <HeaderImageContainer>
          <img src="/assets/images/aligner/aligner_manage.png" width="100%" alt="Aligner Header" />
        </HeaderImageContainer>
      </HeaderContainer>
      {openModal && <ContactModal handleClose={() => setOpenModal(false)} vertical="aligner" />}
    </>
  );
};
