import React from 'react';

import { PageContentContainer, ContentContainer, ImageContainer, PHParagraph, PHTitle } from './PageContentStyles';

interface PageContentProps {
  align?: 'left' | 'right';
  title?: React.ReactNode;
  text: React.ReactNode;
  image: React.ReactNode;
  logos?: React.ReactNode[];
}

export const PageContent = ({ align = 'left', title, text, image, logos = [] }: PageContentProps) => {
  return (
    <PageContentContainer align={align}>
      <h2 hidden>{title}</h2>
      <ImageContainer align={align}>
        <div>{image}</div>
      </ImageContainer>
      <ContentContainer align={align}>
        {title && <PHTitle>{title}</PHTitle>}
        <PHParagraph>{text}</PHParagraph>
        <div>{logos.map(logo => logo)}</div>
      </ContentContainer>
    </PageContentContainer>
  );
};
