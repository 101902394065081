import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';

export const TermsOfUseContentContainer = styled.section`
  width: 750px;
  margin: 180px auto 0;
  padding-bottom: 160px;

  color: ${colors.tertiary};

  p {
    margin: 0 auto;
    width: 555px;
    margin-top: ${spacing.l}px;
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
    p {
      width: auto;
    }
  }
`;

export const Title = styled.h2`
  color: ${colors.secondaryAccent};

  font-family: 'freightBigPro', sans-serif;
  font-size: ${typography.fontSizes.xxxl}px;
  font-weight: normal;

  &:after {
    display: block;

    content: '';

    width: 360px;
    height: 2px;

    margin-top: ${spacing.xxl}px;
    margin-bottom: 120px;

    background-color: ${colors.quaternaryAccent};
  }
`;
