import React from 'react';
import { getForm } from '../../components/layouts/ContactModal/utils';
import {
  OverlayContainer,
  ContactModalContainer,
  ModalTitle,
  TitleBorder,
  IframeContainer
} from '../../components/layouts/ContactModal/ContactModal';

const AdditiveContactModal = () => {
  return (
    <OverlayContainer>
      <ContactModalContainer id="testing">
        {/* <CloseContainer onClick={handleClose}>
          <Icon name="Cross" />
        </CloseContainer> */}
        <ModalTitle>预约演示</ModalTitle>
        <TitleBorder />
        <IframeContainer vertical="additive">{getForm('additive')}</IframeContainer>
      </ContactModalContainer>
    </OverlayContainer>
  );
};

export default AdditiveContactModal;
