import styled from 'styled-components';
import { colors, media, spacing, typography } from '../../foundations';

export const Title = styled.h2`
  margin-top: 150px;

  color: ${colors.secondary};

  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.thin};
  line-height: 1;
`;

export const Sub = styled.sub`
  display: block;
  width: 550px;
  margin-top: 90px;

  color: ${colors.quaternaryAccent};

  font-family: 'freightBigPro', sans-serif;
  font-size: ${typography.fontSizes.xxxxl}px;
  font-weight: ${typography.fontWeights.normal};

  line-height: 1.1;

  @media screen and (max-width: ${media.md}px) {
    width: auto;
  }
`;

export const ContentSection = styled.section`
  display: block;
  width: 740px;
  margin: ${spacing.l}px 0 0 390px;
  padding-bottom: 120px;

  @media screen and (max-width: ${media.xl}px) {
    width: 484px;
    margin-left: auto;
  }

  @media screen and (max-width: ${media.lg}px) {
    width: auto;
  }
`;

export const ContentTitle = styled.h3`
  color: ${colors.secondary};

  font-weight: ${typography.fontWeights.medium};
`;

export const ContentText = styled.p`
  margin-top: ${spacing.l}px;

  color: ${colors.tertiary};

  margin-top: ${spacing.l}px;
`;

export const ContentLink = styled.span`
  color: ${colors.quaternaryAccent};
  border-bottom: solid 2px ${colors.quaternaryAccent};
`;
