import React from 'react';
import { getForm } from '../../components/layouts/ContactModal/utils';
import {
  OverlayContainer,
  ContactModalContainer,
  ModalTitle,
  TitleBorder,
  IframeContainer
} from '../../components/layouts/ContactModal/ContactModal';
import { HelmetContainer } from '../../components/pageComponents/helmet';

const WrapContactModal = () => {
  return (
    <OverlayContainer>
      <HelmetContainer
        image="/assets/images/pages/geomagicWrap/hero.png"
        title="Geomagic Wrap"
        titleTag="免费试用"
        pageDescription="Geomagic Wrap 免费试用申请 | Developed by Oqton"
        description="Geomagic Wrap 免费试用申请 | Developed by Oqton"
      />
      <ContactModalContainer id="testing">
        {/* <CloseContainer onClick={handleClose}>
          <Icon name="Cross" />
        </CloseContainer> */}
        <ModalTitle>免费试用</ModalTitle>
        <TitleBorder />
        <IframeContainer vertical="wrap">{getForm('wrap')}</IframeContainer>
      </ContactModalContainer>
    </OverlayContainer>
  );
};

export default WrapContactModal;
