import React from 'react';

import { ButtonComponent } from '../../V2/Button/Button';
import { TrustedByContainer, TrustedByTitle, LogoContainer, Logo } from './TrustedByStyles';

interface TrustedByProps {
  companies: { name: string; width?: number; link?: string }[];
  customTitle?: string;
  smallPadding?: boolean;
  button?: object;
}

export const TrustedBy = ({ companies, customTitle, smallPadding, button }: TrustedByProps) => {
  const showButton = () => {
    if (button) {
      return <ButtonComponent Theme={'accent-red'} Link={button.Link} Label={button.Label} />;
    } else {
      return <></>;
    }
  };

  return (
    <TrustedByContainer>
      <TrustedByTitle>{customTitle ?? 'Trusted by forward-thinking industry leaders'}</TrustedByTitle>
      <LogoContainer>
        {companies.map((company, index) => {
          if (!company.link) {
            return (
              <Logo smallerWidth={companies.length === 9 && index > 3} key={company.name} smallPadding={smallPadding}>
                <img src={`/assets/images/logos/${company.name}.svg`} alt={`${company.name}`} width={company.width} />
              </Logo>
            );
          } else {
            return (
              <a href={company.link}>
                <Logo smallerWidth={companies.length === 9 && index > 3} key={company.name} smallPadding={smallPadding}>
                  <img src={`/assets/images/logos/${company.name}.svg`} alt={`${company.name}`} width={company.width} />
                </Logo>
              </a>
            );
          }
        })}
      </LogoContainer>

      {showButton()}
    </TrustedByContainer>
  );
};
